import { AxiosError } from 'axios';
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { FieldArrayWithId, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Container, Draggable, OnDropCallback } from 'react-smooth-dnd';

import BaseButton from '@/components/common/Button/BaseButton';
import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { FormContainerDropdown } from '@/components/common/Form/Container/Dropdown';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { FormLayoutUnitDivider } from '@/components/common/Form/Layout/UnitDivider';
import { ModalEducation, ModalEducationBody } from '@/components/common/Modal/Education';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { DATE_MONTH, DATE_YEAR } from '@/definition/DATE_OPTIONS';
import { PLACEHOLDER } from '@/definition/PLACEHOLDER';
import { TSectionProps } from '@/pages/Resume/Edit';
import { notificationError, setPrepareFrag } from '@/redux';
import { EducationData, EducationPatchRequest, ResponseError, ResumeApi } from '@/utils/api-client';

export type TFormValues = {
  educations: {
    e_id: string;
    e_graduate_date_yyyy: string;
    e_graduate_date_mm: string;
    e_school_name: string;
  }[];
};

export const ResumeFormSectionEducations = ({
  id,
  fetchData,
  newEducation,
  setNewEducation,
  setUpdateEducations,
  setDelteEducations,
}:
  TSectionProps &
  {
    newEducation: TFormValues | undefined;
    setNewEducation: Dispatch<React.SetStateAction<TFormValues | undefined>>;
    setUpdateEducations: Dispatch<React.SetStateAction<number[]>>;
    setDelteEducations: Dispatch<React.SetStateAction<{
      e_id: string;
      index: number;
    }[]>>
  }
): React.ReactElement => {
  if (!fetchData || !fetchData.educations) return <></>;

  const [currentEducations, setCurrentEducations] = useState<Array<EducationData>>([]);
  const [educations] = useState<Set<EducationData>>(fetchData.educations);
  const [showEducationEditor, setShowEducationEditor] = useState(false);
  const dispatch = useDispatch();

  const defaultValues: TFormValues = {
    educations: [],
  };

  const educationDataBase = {
    e_id: '',
    e_graduate_date_yyyy: '',
    e_graduate_date_mm: '',
    e_school_name: '',
  };

  const saveEducation = (name: string) => {
    const names = name.split('.'); // e.g : educations.0.e_school_name
    const values = getValues();
    const value = values.educations[Number(names[1])];
    if (!value || !value.e_id) return;

    setUpdateEducations((prevState) => {
      if (prevState.some((state) => state === Number(value.e_id))) return prevState;
      const newState = [...prevState, Number(value.e_id)];
      return newState;
    });
  };

  Array.from(educations.values()).forEach((item: EducationPatchRequest) => {
    if (!defaultValues) return;
    const educationData = Object.assign({}, educationDataBase);
    const splitDate = item.e_graduate_date ? item.e_graduate_date.split('-') : [null, null];
    educationData.e_graduate_date_yyyy = splitDate[0] ? splitDate[0] : '';
    educationData.e_graduate_date_mm = splitDate[1] ? Number(splitDate[1]).toString() : '';
    educationData.e_school_name = item.e_school_name ? item.e_school_name : '';
    educationData.e_id = item.e_id;
    defaultValues.educations.push(educationData);
  });

  // データが4件未満だったら空の入力項目を補充
  if (defaultValues.educations.length < 1) {
    const conditionNum = 1 - defaultValues.educations.length;
    for (let index = 0; index < conditionNum; index++) {
      defaultValues.educations.push(Object.assign({}, educationDataBase));
    }
  }

  const methods = useForm<TFormValues>({
    defaultValues,
  });

  const { control, watch, getValues } = methods;

  const { fields, append, move, remove, update } = useFieldArray({
    control,
    name: 'educations',
  });

  useEffect(() => {
    // 保存されたら更新する
    if (!newEducation) {
      (async () => {
        const response = await new ResumeApi().getResume();
        if(response.data.educations) {
          const educations = Array.from(response.data.educations);
          updateData(educations);
          setNewEducation({educations: educations} as TFormValues);
        }
      })();
    }
  }, [newEducation]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      setNewEducation(value);
      if (!name || name === 'educations') return;
      const names = name.split('.'); // e.g : educations.0.e_school_name
      const educationData = value.educations[Number(names[1])];
      if (name.includes('e_school_name') && educationData.e_school_name !== null) return;
      saveEducation(name);
    });
    return () => subscription.unsubscribe();
  }, []);

  const onBlur = (name: string) => {
    saveEducation(name);
  };

  // 学歴データをアップデート
  const updateData = (newValues: Array<EducationData>) => {
    setCurrentEducations(newValues);
    newValues.forEach((v, i) => {
      const splitDate = v.e_graduate_date ? v.e_graduate_date.split('-') : [undefined, undefined];
      update(i, {
        e_id: v.e_id,
        e_graduate_date_yyyy: splitDate[0] ? Number(splitDate[0]).toString() : '',
        e_graduate_date_mm: splitDate[1] ? Number(splitDate[1]).toString() : '',
        e_school_name: v.e_school_name,
      });
    });
  };
  // 新規処理の場合はIDのみ先に反映する
  // const updateDataID = (newValue: Array<EducationData>) => {
  //   setCurrentEducations(newValue);
  //   newValue.forEach((v, i) => {
  //     const splitDate = v.e_graduate_date ? v.e_graduate_date.split('-') : [undefined, undefined];
  //     update(i, {
  //       e_id: v.e_id,
  //       e_graduate_date_yyyy: splitDate[0],
  //       e_graduate_date_mm: splitDate[1] ? Number(splitDate[1]).toString() : '',
  //     });
  //   });
  // };

  // 学歴追加
  const onAddEducation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    append(educationDataBase);
  };

  // 学歴削除
  const onRemove = (e_id: string, index: number) => {
    // 未保存の場合は削除対象にしない
    if (e_id) {
      setUpdateEducations((prevState) => {
        if (!prevState.includes(Number(e_id))) return prevState;
        const newState = prevState.filter((state) => state !== Number(e_id));
        return newState;
      });

      setDelteEducations((prevState) => {
        const newState: {e_id: string, index: number}[] = [...prevState, {e_id: e_id, index: index}];
        return newState;
      });
    }
    remove(index);
  };

  // react-smooth-dnd の onDrop で発火
  // このイベントで渡される引数によって元々どのインデックスの要素が消えて、どのインデックスに差し込まれたのか判定
  const onDrop: OnDropCallback = ({ removedIndex, addedIndex }) => {
    if (removedIndex !== null && addedIndex !== null) {
      move(removedIndex, addedIndex);
    }
  };

  // スマホは別窓で並び替えと削除を処理する
  const onShowEducationEditor = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    updateData(currentEducations);
    setShowEducationEditor(true);
  };
  const onHideEducationEditor = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setShowEducationEditor(false);
  };

  const setBeforeUnload = (flag: boolean) => {
    dispatch(setPrepareFrag(flag));
  };

  return (
    <div id={id}>
      <FormProvider {...methods}>
        <form>
          <TitleSectionTitle>
            <div className="flex items-center">
              <span className="mr-4">学歴</span>
              <TooltipMessageMatchbou
                className=""
                message="オファー機能ご利用時、「最終学歴以外」は企業に開示されません。"
                position={['0', '-94px']}
              >
                <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
              </TooltipMessageMatchbou>
            </div>
          </TitleSectionTitle>
          <Container
            onDrop={onDrop}
            dragHandleSelector=".dragHandle"
            render={(ref) => {
              return (
                <div ref={ref} className="mb-8 sm:mb-18 mbx-formUnit-education">
                  {fields.map((item, index) => {
                    return (
                      <Draggable key={item.id} className="mbx-formUnit-education-box">
                        <EducationField
                          index={index}
                          item={item}
                          onRemove={onRemove}
                          onBlur={onBlur}
                          setBeforeUnload={setBeforeUnload}
                        />
                      </Draggable>
                    );
                  })}
                </div>
              );
            }}
          ></Container>
          <BaseButton iconName="Add" size="s" theme="tertiary" onClick={onAddEducation}>
            学歴を追加
          </BaseButton>
          <span className="ml-8 lg:hidden md:hidden">
            <BaseButton
              iconName="Sortable"
              size="s"
              theme="quaternary"
              onClick={onShowEducationEditor}
            >
              並び替え
            </BaseButton>
          </span>
        </form>
      </FormProvider>
      {showEducationEditor && (
        <ModalEducation>
          <ModalEducationBody
            fields={fields}
            onDrop={onDrop}
            onClose={onHideEducationEditor}
            onDelete={onRemove}
          ></ModalEducationBody>
        </ModalEducation>
      )}
    </div>
  );
};

type TFieldProps = {
  index: number;
  item: FieldArrayWithId<TFormValues, 'educations', 'id'>;
  onRemove: (e_id: string, index: number) => void;
  onBlur(name: string): void;
  setBeforeUnload(flag: boolean): void;
};

const EducationField = ({
  index,
  item,
  onRemove,
  onBlur,
  setBeforeUnload,
}: TFieldProps): React.ReactElement => {
  return (
    <FormLayoutFieldset>
      <></>
      <div className="flex">
        <div className="flex flex-auto sm:flex-wrap">
          <FormContainerDropdown
            defaultValue={`${item.e_graduate_date_yyyy}`}
            name={`educations.${index}.e_graduate_date_yyyy`}
            placeholder={PLACEHOLDER.year}
            className="w-92 mkt_mask_items"
            selectList={DATE_YEAR}
          />
          <FormLayoutUnitDivider className="ml-8">年</FormLayoutUnitDivider>

          <FormContainerDropdown
            defaultValue={`${item.e_graduate_date_mm}`}
            name={`educations.${index}.e_graduate_date_mm`}
            placeholder={PLACEHOLDER.month}
            className="w-92 ml-8 mkt_mask_items"
            selectList={DATE_MONTH}
          />
          <FormLayoutUnitDivider className="ml-8">月</FormLayoutUnitDivider>
          <FormContainerTextfield
            defaultValue={`${item.e_school_name ? item.e_school_name : ''}`}
            name={`educations.${index}.e_school_name`}
            placeholder={PLACEHOLDER.e_school_name}
            className="flex-auto ml-8 sm:w-auto sm:ml-0 sm:mt-8 mkt_mask_items"
            onBlur={() => onBlur(`educations.${index}.e_school_name`)}
            onFocus={() => setBeforeUnload(true)}
          />
        </div>
        <div className="sm:hidden">
          <div className="flex">
            <ButtonIconButton
              type="gray"
              iconName="Sortable"
              className="dragHandle"
              focus={false}
            ></ButtonIconButton>
            <ButtonIconButton
              type="gray"
              iconName="Clear"
              focus={false}
              onClick={() => onRemove(item.e_id, index)}
            ></ButtonIconButton>
          </div>
        </div>
      </div>
    </FormLayoutFieldset>
  );
};
