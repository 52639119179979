import React from 'react';
import { FieldValues, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { FormContainer, TCallback } from '@/components/common/Form/Container';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { FormLayoutUnitDivider } from '@/components/common/Form/Layout/UnitDivider';
import { FormRadioButtonItem } from '@/components/common/Form/RadioButtonItem';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { PLACEHOLDER } from '@/definition/PLACEHOLDER';
import { usePolling } from '@/hooks/usePolling';
import { TSectionProps } from '@/pages/Resume/Edit';
import { setInputClearingInProgress } from '@/redux';
import { ResumeApi } from '@/utils/api-client';

export const ResumeFormSectionFamily = ({ id }: TSectionProps): React.ReactElement => {

  return (
    <div id={id}>
      <TitleSectionTitle>
        <div className="flex items-center">
          <span className="mr-4">家族について</span>
          <TooltipMessageMatchbou
            className=""
            message="オファー機能ご利用時、「家族について」は企業に開示されません。"
            position={['0', '-94px']}
          >
            <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
          </TooltipMessageMatchbou>
        </div>
      </TitleSectionTitle>
      <div className="mbx-utils-stack-v--16">
        <FormLayoutFieldset>
          <FormLabel label="扶養家族数（配偶者を除く）" type="small"></FormLabel>
          <div className="flex mbx-utils-stack-h--8">
            <FormContainerTextfield
              name="dependent_family"
              placeholder={PLACEHOLDER.dependent_family}
              type="number"
              min={0}
              className="w-150"
            />
            <FormLayoutUnitDivider>人</FormLayoutUnitDivider>
          </div>
        </FormLayoutFieldset>
        <FormLayoutFieldset>
          <FormLabel label="配偶者" type="small"></FormLabel>
          <div className="mbx-utils-stack-h--54 flex w-150 mkt_mask_items">
            <FormRadioButtonItem
              label="有"
              name="partner"
              value="true"
              className="flex-1"
            ></FormRadioButtonItem>
            <FormRadioButtonItem
              label="無"
              name="partner"
              value="false"
              className="flex-1"
            ></FormRadioButtonItem>
          </div>
        </FormLayoutFieldset>
        <FormLayoutFieldset>
          <FormLabel label="配偶者の扶養義務" type="small"></FormLabel>
          <div className="mbx-utils-stack-h--54 flex w-150 mkt_mask_items">
            <FormRadioButtonItem
              label="有"
              name="duty_of_support"
              value="true"
              className="flex-1"
            ></FormRadioButtonItem>
            <FormRadioButtonItem
              label="無"
              name="duty_of_support"
              value="false"
              className="flex-1"
            ></FormRadioButtonItem>
          </div>
        </FormLayoutFieldset>
      </div>
    </div>
  );
};
