import React, { useEffect, useMemo, useState, Dispatch, SetStateAction } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';

import * as CH from '@/components/CareerHistory';
import { BaseButton } from '@/components/common/Button';
import { ModalEditable, ModalEditableBody } from '@/components/common/Modal/Editable';
import { TCareerFormValues } from '@/types/Pages/CareerHistory';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type TFieldProps = {
  index: number;
  item: FieldArrayWithId<TCareerFormValues, 'careers', 'id'>;
  invisible?: boolean;
  onVisible?: React.MouseEventHandler<HTMLButtonElement>;
  onRemove?(): void;
  open: boolean;
  onBlur?(
    name: string,
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
  ): void;
  onFocus?(e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>): void;
  setDeleteProjects: Dispatch<SetStateAction<string[]>>;
};

export const Editable: React.VFC<TFieldProps> = ({
  item,
  invisible,
  onVisible,
  onRemove,
  index,
  open,
  onBlur,
  onFocus,
  setDeleteProjects,
}) => {
  const { register } = useFormContext();
  const [currentlyInOffice, setCurrentlyInOffice] = useState<boolean>(false);
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const { listeners, transform, transition, setNodeRef } = useSortable({ id: item.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    setCurrentlyInOffice(!item.c_leave_flag);
  }, [item]);

  useEffect(() => {
    setShowEditor(open);
  }, [open]);

  const join_date = useMemo(() => {
    return item.c_join_date.split('-');
  }, [item]);

  const leave_date = useMemo(() => {
    if (!item.c_leave_date) return ['', ''];
    return item.c_leave_date.split('-');
  }, [item]);

  return (
    <div style={style} ref={setNodeRef}>
      <CH.DraggableSection className="" title="職歴" invisible={invisible} onVisible={onVisible} onRemove={onRemove} listeners={listeners}>
        <>
          <input type="hidden" {...register(`careers.${index}.c_id`)} />

          <div className="space-y-8">
            <div className="text-body_1 mkt_mask_items">
              <input type="text" disabled {...register(`careers.${index}.c_company_name`)} id="" />
            </div>

            <div className="text-body_2 mkt_mask_items">
              {join_date[0]}年{join_date[1]}月 〜{currentlyInOffice && <> 現在</>}
              {!currentlyInOffice && (
                <>
                  {leave_date[0]}年{leave_date[1]}月
                </>
              )}
            </div>

            <div className="">
              <BaseButton
                theme="quaternary"
                size="m"
                iconName="Edit"
                className="w-full"
                onClick={(e) => {
                  e.preventDefault();
                  setShowEditor(true);
                }}
              >
                編集
              </BaseButton>
            </div>
          </div>
        </>
      </CH.DraggableSection>

      {showEditor && (
        <ModalEditable>
          <ModalEditableBody onClose={() => setShowEditor(false)}>
            <>
              <h1 className="p-15 bg-gray-400 text-14_20 font-bold">職歴</h1>
              <div className="p-15 border-gray-700 bg-gray-100">
                <CH.Career
                  {...{
                    index,
                    item,
                    onBlur,
                    onFocus,
                    setDeleteProjects,
                  }}
                />
              </div>
            </>
          </ModalEditableBody>
        </ModalEditable>
      )}
    </div>
  );
};
