import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { usePagePortfolio } from '@/hooks/usePagePortfolio';
import { setPortfolio } from '@/redux';
import { State } from '@/redux/state';
import {
    Portfolio, PortfolioItem, PortfolioPage, PortfolioPagePPageLayoutEnum, Status
} from '@/utils/api-client/index';
import { p_contents, updateQuestion } from '@/utils/updateQuestion';

const defaultPage: PortfolioPage = {
  ...Object.assign({
    p_id: 0,
    p_page_layout: PortfolioPagePPageLayoutEnum.Questions,
  }),
  p_contents,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDetailEdit = () => {
  const { portfolio } = usePagePortfolio();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userId, setUserId] = useState<string>();
  const user = useSelector((state: State) => state.user);
  const history = useHistory();
  const pfItemId = parseInt(history.location.pathname.split('/')[4]);
  const dispatch = useDispatch();

  useEffect(() => {
    setUserId(user?.user_id);
  }, [user]);

  const pfItem = useMemo<PortfolioItem | undefined>(() => {
    if (!portfolio) return undefined;
    if (!portfolio.items) return undefined;
    return Array.from(portfolio.items).find((item: PortfolioItem) => item.i_id === pfItemId);
  }, [portfolio]);


  useEffect(() => {
    if (!pfItem) return;
    //i_pagesが0の場合、Questionの初期状態のものを一度patchする
    if (Array.from(pfItem.i_pages).length === 0) {
      if (!portfolio) return;
      const items: PortfolioItem[] = Array.from(portfolio.items?.values() || []).map(
        (item: PortfolioItem) => {
          if (item.i_id !== pfItemId) return item;
          return {
            ...Object.assign(item),
            i_pages: [defaultPage],
          };
        }
      );

      const newPortfolio = {
        ...Object.assign(portfolio),
        items,
      };

      dispatch(setPortfolio(newPortfolio));
    }
  }, [pfItem]);

  const setQuestionParam = (qId: string, sections: string[], question?: string) => {
    if (!portfolio) return;

    let newPortfolio: (Portfolio & Status) | undefined;
    let userId: string | undefined;

    //関数型のsetStateで現在のstateを得る
    const setPFDataPromise = new Promise<void>((resolve) => {
      newPortfolio = updateQuestion(portfolio, pfItemId, qId, sections, question);
      resolve();
      return newPortfolio;
    });

    const setUserIdPromise = new Promise<void>((resolve) => {
      setUserId((prevId) => {
        userId = prevId;
        resolve();
        return prevId;
      });
    });

    Promise.all([setPFDataPromise, setUserIdPromise]).then(() => {
      if (userId && newPortfolio) {
        dispatch(setPortfolio(newPortfolio));
      }
    });
  };

 // eslint-disable-next-line @typescript-eslint/no-explicit-any
 const setItem = (param: any) => {
    if (portfolio && portfolio.items) {
      const newPortfolio = {
        ...Object.assign(portfolio),
        items: Array.from(portfolio.items).map((i: PortfolioItem) => {
          if (i.i_id === pfItemId) {
            return {
              ...i,
              ...param,
            };
          } else {
            return i;
          }
        }),
      };
      dispatch(setPortfolio(newPortfolio));
    }
  };

  return {
    pfItem,
    pfItemId,
    setQuestionParam,
    setItem,
  };
};
