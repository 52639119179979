import React, { Dispatch, SetStateAction } from 'react';

import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { FormRadioButtonItem } from '@/components/common/Form/RadioButtonItem';
import { LayoutColumDivider } from '@/components/common/Layout/ColumDivider';
import { ProfileImageSquare } from '@/components/common/OtherComponents/ProfileImageSquare';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { TSectionProps } from '@/pages/Resume/Edit';
import { FileResponse, ImagePhoto } from '@/utils/api-client';

export const ResumeFormSectionProfile = ({
  id,
  iconSrc,
  setIconSrc,
  setIconState
}:
TSectionProps &
{
  iconSrc: string|undefined;
  setIconSrc: Dispatch<SetStateAction<string|undefined>>;
  setIconState: Dispatch<SetStateAction<'stay'| 'change' | 'delete'>>;
}): React.ReactElement => {
  const onIconSave = (res: any) => {
    setIconState('change');
    setIconSrc(res);
  };

  const onIconClick = async () => {
    if (!iconSrc) return;
    setIconState('delete');
    setIconSrc(undefined);
  }

  return (
    <div id={id}>
      <TitleSectionTitle>
        <div className="flex items-center">
          <span className="mr-4">プロフィール</span>
          <TooltipMessageMatchbou
            className=""
            message="オファー機能ご利用時、「氏名」「ふりがな」「生年月日」は企業に開示されません。"
            position={['0', '-94px']}
          >
            <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
          </TooltipMessageMatchbou>
        </div>
      </TitleSectionTitle>
      <LayoutColumDivider
        className={['mbx-utils-stack-v--16 sm:mt-32', 'flex items-center justify-center']}
      >
        <>
          <FormLayoutFieldset>
            <FormLabel label="氏名" type="small">
              <FormCaption></FormCaption>
            </FormLabel>
            <FormContainerTextfield name="name" readOnly={true} className="mkt_mask_items" />
          </FormLayoutFieldset>
          <FormLayoutFieldset>
            <FormLabel label="ふりがな" type="small">
              <FormCaption></FormCaption>
            </FormLabel>
            <FormContainerTextfield name="furigana" readOnly={true} className="mkt_mask_items" />
          </FormLayoutFieldset>
          <FormLayoutFieldset>
            <FormLabel label="性別" type="small"></FormLabel>
            <div className="mbx-utils-stack-h--54 flex mkt_mask_items">
              <FormRadioButtonItem label="男" name="gender" value="2"></FormRadioButtonItem>
              <FormRadioButtonItem label="女" name="gender" value="1"></FormRadioButtonItem>
              <FormRadioButtonItem
                label="選択しない"
                name="gender"
                value="0"
              ></FormRadioButtonItem>
            </div>
          </FormLayoutFieldset>
          <FormLayoutFieldset>
            <FormLabel label="生年月日" type="small">
              <FormCaption></FormCaption>
            </FormLabel>
            <FormContainerTextfield name="birth" readOnly={true} className="mkt_mask_items" />
          </FormLayoutFieldset>
        </>
        <>
          <div className="flex flex-col items-center">
            <h1 className="text-12_16 font-bold py-6 mb-4 text-center">証明写真</h1>
            <ProfileImageSquare src={iconSrc} onIconSave={onIconSave} className="mkt_mask_items w-104" />
            <div className="pt-16 text-center">
              <p className="text-caption_2 text-gray-700">横208px × 縦288px推奨、最大5MB</p>
            </div>
            {iconSrc &&
              <div className="pt-16 text-center">
                <div className="base-button isTextLink" onClick={onIconClick}>
                  <p className="button size-s theme-link flex items-center">
                    <i className="mbx-icon mbx-icon-Trash mr-2"></i>
                    <span className="font-bold label">画像を削除</span>
                  </p>
                </div>
              </div>
            }
          </div>
        </>
      </LayoutColumDivider>
    </div>
  );
};

const FormCaption = (): React.ReactElement => {
  return (
    <>
      <ButtonTextLink href="/mypage/profile">基本情報</ButtonTextLink>から編集できます
    </>
  );
};
