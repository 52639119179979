import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { setPortfolio, setOldPortfolio, forceLoadingOff, forceLoadingOn,toggleLoading, notificationError, userInfoForceUpdate, setPrepareFrag, setSaved } from '@/redux';
import { State } from '@/redux/state';
import { PortfolioApi, PortfolioProgressResponse, Status, UserInfo, Portfolio, ResponseError, UserPatchRequest, UserApi, PortfolioItem, Profile } from '@/utils/api-client/api';

type TProps = {
  userId: string | undefined;
  portfolio: (Portfolio & Status) | undefined;
  oldPortfolio: (Portfolio & Status) | undefined;
  patchPortfolio: (data?: Portfolio & Status) => Promise<void>;
  progressData: (any & Status) | undefined;
  setBeforeUnload: (flag: boolean) => void;
  setBannerImageUpdate: (file: string) => void;
  setUserUpdate: (userData: {
    name: string | null,
    job_title: string | null,
    icon: string | null
  }) => void;
  errorCode: string | null | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePagePortfolio = (): TProps => {
  const user: UserInfo | null = useSelector((state: State) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const { push } = useContext(DataLayerContext);
  const portfolio: (Portfolio & Status) | undefined = useSelector((state: State) => state.portfolio);
  // 更新時エラーが起きた時のロールバック用の値
  const oldPortfolio: (Portfolio & Status) | undefined = useSelector((state: State) => state.oldPortfolio);
  const [progressData, setProgressData] = useState<PortfolioProgressResponse>();
  const [errorCode, setErrorCode] = useState<string | null | undefined>(null);
  const userId = useMemo((): string | undefined => {
    return (user?.user_id) ? user?.user_id : undefined;
  }, [user]);

  useEffect(() => {
    (async () => {
      const pathname = history.location.pathname;
      if (!userId || !/^\/mypage\/portfolio\/P\d+\/\d+\/edit$/.test(pathname)) return;
      const id = pathname.split('/')[4];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: AxiosResponse<any & Status> =
        await new PortfolioApi().getPortfolioProgressDetail(userId, id);
        setProgressData(response.data);
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      if (!userId) return;

      try {
        dispatch(toggleLoading(true));
        const portfolioResponse = await new PortfolioApi().getPortfolioEdit(userId);
        setErrorCode(undefined);
        dispatch(setPortfolio(portfolioResponse.data));
        dispatch(setOldPortfolio(portfolioResponse.data));
      } catch (error) {
        const e = error as AxiosError<ResponseError>;
        if (e.response) setErrorCode(e.response.data.error_code);
      } finally {
        dispatch(toggleLoading(false));
      }
    })();
  }, [user]);

  //ポートフォリオ変更
  const patchPortfolio = async (data?: Portfolio & Status) => {
    dispatch(forceLoadingOn());
    dispatch(toggleLoading(true));
    const patchData = cloneDeep(data || portfolio);
    if (!userId || !patchData) return;
    try {
      // バナー画像
      if (patchData.profile?.main_image && !patchData.profile.main_image.f_id) {
        const file = patchData.profile.main_image.f_url;
        if (file) {
          const f = await new PortfolioApi().postFiles(file);
          dataLayerFilePush();
          patchData.profile.main_image = f.data;
        } else if (!file && oldPortfolio?.profile?.main_image.f_id) {
          patchData.profile.main_image = {} as any;
        }
      }

      // ロゴ画像変更
      if (patchData.page_title_logo && Object.keys(patchData.page_title_logo).length !== 0 && !patchData.page_title_logo.f_id) {
        const f = await new PortfolioApi().postFiles(patchData.page_title_logo.f_url);
        patchData.page_title_logo = f.data;
        dataLayerFilePush();
      }

      await new PortfolioApi().patchPortfolioEdit(userId, patchData);

      // バナー画像写真削除
      if (oldPortfolio?.profile?.main_image.f_id && (patchData?.profile?.main_image.f_id !== oldPortfolio.profile.main_image.f_id)) {
        await new PortfolioApi().deleteFiles(oldPortfolio.profile.main_image.f_id);
      }

      // ロゴ画像削除
      if (oldPortfolio?.page_title_logo?.f_id && (patchData?.page_title_logo?.f_id !== oldPortfolio.page_title_logo.f_id)) {
        await new PortfolioApi().deleteFiles(oldPortfolio.page_title_logo.f_id);
      }

      // ユーザー画像変更or削除
      if (patchData.profile && user) {
        // ユーザー情報編集APIの必須項目対応で便宜上ここでは不要な情報もリクエスト
        const data: UserPatchRequest = {
          name: user.name,
          furigana: user.furigana,
          birth_year: user.birth_year,
          birth_month: user.birth_month,
          birth_day: user.birth_day,
          matchbox_id: user.matchbox_id as string,
          icon: patchData.profile.icon,
        };
        const res = await new UserApi().patchUser(data);
        if (res.data.user_info) {
          dispatch(userInfoForceUpdate(res.data.user_info));
          dataLayerFilePush();
        }
      }

      const res = await new PortfolioApi().getPortfolioEdit(userId);
      const newPortfolio = res.data;
      dispatch(setPortfolio(newPortfolio));
      dispatch(setOldPortfolio(newPortfolio));
      setBeforeUnload(false);
      dispatch(setSaved(true));
    } catch(error) {
      // ロールバック処理

      // バナー画像削除
      if (patchData?.profile?.main_image.f_id && (patchData?.profile?.main_image.f_id !== oldPortfolio?.profile?.main_image.f_id)) {
        await new PortfolioApi().deleteFiles(patchData.profile.main_image.f_id);
      }

      // ロゴ画像削除
      if (patchData?.page_title_logo?.f_id && (patchData?.page_title_logo?.f_id !== oldPortfolio?.page_title_logo?.f_id)) {
        await new PortfolioApi().deleteFiles(patchData.page_title_logo.f_id);
      }

      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
      if (e.response?.status === 400 && patchData.items) {
        // エラーになった場合でも作品の並び替えはされてしまうので保存前の値で更新する
        await new PortfolioApi().patchPortfolioEdit(userId, oldPortfolio);
        const items = Object.assign(patchData);
        items.items.map((item: PortfolioItem) => {
          if (item.i_id && oldPortfolio && oldPortfolio.items) {
            const oldItem = Array.from(oldPortfolio.items).find((oldItem) => oldItem.i_id === item.i_id);
            if (oldItem) item.i_visible = oldItem.i_visible;
            return item;
          }
        });
        dispatch(setPortfolio(items));
      }
    } finally {
      dispatch(forceLoadingOff());
      dispatch(toggleLoading(false));
    }
  }

  // バナー画像変更
  const setBannerImageUpdate = (file: string) => {
    setBeforeUnload(true);
    const copy = cloneDeep(portfolio) as Portfolio & Status;
    const profile = {...copy?.profile, main_image: {f_id: '', f_url: file, f_thumbnail: ''}} as Profile;
    copy.profile = profile;
    dispatch(setPortfolio(copy));
  };

  // ユーザー情報変更
  const setUserUpdate = (userData: {
    name: string | null,
    job_title: string | null,
    icon: string | null,
  }) => {
    setBeforeUnload(true);
    const copy = cloneDeep(portfolio) as Portfolio & Status;
    const profile = {...copy?.profile, name: userData.name, job_title: userData.job_title, icon: userData.icon} as Profile;
    copy.profile = profile;
    dispatch(setPortfolio(copy));
  };

  const setBeforeUnload = (flag: boolean) => {
    dispatch(setPrepareFrag(flag));
  };

  const dataLayerFilePush = () => {
    push({
      event: 'fileUpload',
      actionType: 'file_upload',
      actionName: '画像',
    });
  }

  return {
    userId,
    portfolio,
    oldPortfolio,
    patchPortfolio,
    progressData,
    setUserUpdate,
    setBeforeUnload,
    setBannerImageUpdate,
    errorCode,
  };
};
