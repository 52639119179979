import '@/styles/common/Portfolio/MainVisual/add_link.scss';
import '@/styles/common/Portfolio/MainVisual/blank.scss';

import { AxiosResponse, AxiosError } from 'axios';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CropperModal } from '@/components/common/Modal/Cropper';
import { AddLinkRegister, TypeSelect } from '@/components/common/Portfolio/';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { IMAGE_SIZE } from '@/definition/IMAGE_SIZE';
import { useFileLimit } from '@/hooks/useFIleLimit';
import { toggleLoading, notificationError } from '@/redux/index';
import { State } from '@/redux/state';
import { Embed, PortfolioItem, PortfolioApi, ResponseError, FileResponse, Portfolio, Status } from '@/utils/api-client/index';

import { EmbeddedLink } from './EmbeddedLink';
import { Image } from './Image';

type Props = {
  item: PortfolioItem | undefined;
  updatePortfolioItem(param: any): void;
  oldPortfolio: (Portfolio & Status) | undefined;
};

export function MainVisual(props: Props): React.ReactElement {
  const dispatch = useDispatch();
  const { push } = useContext(DataLayerContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const user = useSelector((state: State) => state.user);
  const [status, setStatus] = useState<'blank' | 'url' | 'image' | 'embed'>();
  const [uploadImageType, setUploadImageType] = useState<'main' | 'thumb'>();
  const [imageUrl, setImageUrl] = useState<string>();
  const [embedUrl, setEmbedUrl] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [image, setImage] = useState<any>();
  const { checkFileSize } = useFileLimit();
  const removeMainImage = async () => {
    setImageUrl(undefined);
    props.updatePortfolioItem({
      i_image: null,
    });
    const image = props.item?.i_image;
    const isOldImage = Array.from(props.oldPortfolio?.items?.values() || []).some((item) => item.i_image?.f_id === image?.f_id);

    if (!image || isOldImage) return;
    await new PortfolioApi().deleteFiles(image?.f_id);
  };

  const removeEmbed = async () => {
    setEmbedUrl(undefined);
    setImageUrl(undefined);
    props.updatePortfolioItem({
      i_image: null,
      i_embedlink: null,
    });
    const image = props.item?.i_image;
    const isOldImage = Array.from(props.oldPortfolio?.items?.values() || []).some((item) => item.i_image?.f_id === image?.f_id);

    if (!image || isOldImage) return;
    await new PortfolioApi().deleteFiles(image?.f_id);
  };

  const uploadMainImage = (res: any) => {
    if (!res) return;
    if (!user?.user_id) return;
    props.updatePortfolioItem({
      i_image: res,
      i_embedlink: null,
    });
  };
  const uploadThumbnailImage = (res: any) => {
    if (!res) return;
    if (!user?.user_id) return;
    props.updatePortfolioItem({
      i_image: res,
    });
  };
  const uploadEmbed = async (embed: Embed) => {
    if (!user?.user_id) return;
    props.updatePortfolioItem({
      i_embedlink: embed.embed,
      i_image: embed.image,
    });
  };
  const onChange = (e: any) => {
    onLoad(e.target.files[0]);
  };
  const onClick = () => {
    setStatus('image');
  };
  const onFileSelect = (type: 'main' | 'thumb') => {
    setUploadImageType(type);
    inputRef.current?.click();
  };
  const onClickLink: React.MouseEventHandler<HTMLButtonElement> = () => {
    setStatus('url');
  };
  const onCancelLink: React.MouseEventHandler<HTMLDivElement> = () => {
    setStatus('blank');
  };
  const onLoad = (file: any) => {
    if (!file || !checkFileSize(file.size, 5)) return;

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    // 画像の読み込み
    reader.readAsDataURL(file);

    setIsOpen(true);
  };
  const onRemove: React.MouseEventHandler<HTMLDivElement> = () => {
    removeMainImage();
    setStatus('blank');
  };

  const onEmbedRemove = () => {
    removeEmbed();
    setStatus('blank');
  };

  const postImage = async (data: any) => {
    try {
      dispatch(toggleLoading(true));

      const image = props.item?.i_image;
      const isOldImage = Array.from(props.oldPortfolio?.items?.values() || []).some((item) => item.i_image?.f_id === image?.f_id);
      if (image?.f_id && !isOldImage) await new PortfolioApi().deleteFiles(image.f_id);

      const res: AxiosResponse<FileResponse> = await new PortfolioApi().postFiles(data);
      push({
        event: 'fileUpload',
        actionType: 'file_upload',
        actionName: '画像',
      });
      switch (uploadImageType) {
        case 'main':
          uploadMainImage(res.data);
          setImageUrl(`${res.data.f_url}`);
          setStatus('image');
          break;
        case 'thumb':
          uploadThumbnailImage(res.data);
          setImageUrl(`${res.data.f_url}`);
          setStatus('embed');
          break;
      }
      setIsOpen(false);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  }

  useEffect(() => {
    if (!props.item) return;
    if (props.item.i_embedlink) {
      setStatus('embed');
      setEmbedUrl(props.item.i_embedlink);
      if (props.item.i_image && props.item.i_image.f_id) {
        setImageUrl(props.item.i_image.f_thumbnail);
      }
      return;
    }

    if (props.item.i_image && props.item.i_image.f_id) {
      const img = document.createElement('img');
      img.onload = () => {
        setImageUrl(props?.item?.i_image.f_url);
        setStatus('image');
      };
      img.onerror = () => {
        setStatus('blank');
      };
      img.src = props.item.i_image.f_url;

      return;
    }
    setStatus('blank');
  }, [props.item]);

  const onPostEmbedURL = (embed: Embed) => {
    if (embed.embed) setEmbedUrl(embed.embed);
    if (embed.image) setImageUrl(embed.image.f_url);
    uploadEmbed(embed);
  };

  useEffect(() => {
    if (embedUrl !== undefined) {
      setStatus('embed');
    } else if (imageUrl !== undefined) {
      setStatus('image');
    }
  }, [imageUrl, embedUrl]);

  return (
    <div className="mb-30 sm:-mx-24">
      <input
        className="mbx-type_select_input-file"
        type="file"
        ref={inputRef}
        name=""
        id=""
        onChange={onChange}
      />

      {status === 'blank' && (
        <section className="mbx-blank flex justify-center items-center">
          <TypeSelect
            className="w-288"
            size={`横${IMAGE_SIZE.pf_main.width}px × 縦${IMAGE_SIZE.pf_main.height}px推奨、最大5MB`}
            onClick={onClick}
            onClickLink={onClickLink}
          />
        </section>
      )}
      {status === 'url' && (
        <section className="mbx-add_link">
          <AddLinkRegister
            onSend={() => {
              /** */
            }}
            onCancel={onCancelLink}
            onPost={onPostEmbedURL}
          />
        </section>
      )}
      {status === 'image' && (
        <Image
          src={imageUrl}
          onClick={onClick}
          onRemove={onRemove}
          onLoadImage={(e) => {
            setUploadImageType('main');
            onLoad(e);
          }}
          onCancel={onCancelLink}
        />
      )}
      {status === 'embed' && (
        <EmbeddedLink
          src={embedUrl}
          thumbnail={imageUrl}
          onRemove={onEmbedRemove}
          onSetThumbnailClick={() => onFileSelect('thumb')}
        />
      )}
      <CropperModal
        isOpen={isOpen}
        width={IMAGE_SIZE.pf_main.width}
        height={IMAGE_SIZE.pf_main.height}
        src={image}
        postImage={postImage}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
}
