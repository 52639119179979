import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { OnDropCallback } from 'react-smooth-dnd';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { useSearchCondition } from '@/hooksDirect/useSearchCondition';
import { conditionFromQuery, DataLayerType } from '@/hooksDirect/common/useDataLayer';
import { notificationError, notificationSuccess } from '@/reduxAdmin/modules/utils';
import { TConditionsFormValues } from '@/types/Pages/SearchConditions';
import { DirectApi, DirectSearchConditionResponse, DirectSearchConditionModel, ResponseError } from '@/utils/api-client';

// import { testConditionsData } from '@/debug/directConditions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDirectSearchConditions = (recommended: boolean) => {
  const DirectAPI = new DirectApi();
  const dispatch = useDispatch();
  const history = useHistory();
  const [conditions, setConditions] = useState<DirectSearchConditionResponse[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const { push } = useContext(DataLayerContext);
  const { saveSearchCondition } = useSearchCondition();

  const methods = useForm<TConditionsFormValues>({
    defaultValues: {
      conditions,
    },
  });

  const { fields, move, remove } = useFieldArray<TConditionsFormValues, 'conditions', 'uid'>({
    control: methods.control,
    name: 'conditions',
    keyName: 'uid',
  });

  const getConditionByIndex = (index: number) => {
    return methods.getValues(`conditions`)[index];
  };

  useEffect(() => {
    DirectAPI.getDirectSearchConditions(recommended)
      .then((res) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { conditions } = res.data as any;
        setConditions(conditions);

        // ↓ 一旦テストデータで確認できるように
        // setTestData();

        setLoaded(true);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) {
          dispatch(notificationError(error.response.data.error_message));
        }
      });
  }, []);

  useEffect(() => {
    methods.setValue('conditions', conditions);
  }, [conditions]);

  const onDrop: OnDropCallback = ({ removedIndex, addedIndex }) => {
    if (removedIndex !== null && addedIndex !== null) {
      move(removedIndex, addedIndex);

      const conditions: DirectSearchConditionResponse[] = methods.getValues(
        `conditions`
      ) as DirectSearchConditionResponse[];
      const ids = conditions.map((item) => {
        return item.id;
      });

      DirectAPI.patchDirectSearchConditions(recommended, { ids })
        .then(() => {
          dispatch(notificationSuccess('保存しました。'));
        })
        .catch((error: AxiosError<ResponseError>) => {
          if (error.response) {
            dispatch(notificationError(error.response.data.error_message));
          }
        });
    }
  };

  const onRemove = (index: number, id: number) => {
    const condition = methods.getValues(`conditions`)[index];
    const dataLayerCondition = conditionFromQuery(
      undefined,
      condition as DirectSearchConditionResponse
    );

    let param: DataLayerType = {
      event: 'deleteConditions',
      actionType: 'delete_conditions',
      actionName: 'delete_conditions',
    };
    if (condition) {
      param = {
        ...param,
        ...dataLayerCondition,
      };
    }
    push(param);

    remove(index);

    DirectAPI.deleteDirectSearchConditionId(id)
      .then(() => {
        dispatch(notificationSuccess('保存しました。'));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) {
          dispatch(notificationError(error.response.data.error_message));
        }
      });
  };

  const onClickEditButton = (id: number) => {
    // 保存した検索条件の編集画面に遷移
    history.push(`/direct/conditions/${id}`);
  };

  const onClickSearchButton = (index: number, search_condition_id: number) => {
    const params = [`search_condition_id=${search_condition_id}`];
    /**
     * おすすめの検索条件の場合はパラメーター追加
     */
    if (recommended) {
      params.push(`recommended=true`);
    }
    const condition = cloneDeep(getConditionByIndex(index));
    saveSearchCondition(condition as DirectSearchConditionModel);
    history.push(`/direct/search/result?${params.join('&')}`);
  };

  const onChangeDirectMail = (index: number) => {
    const condition = methods.getValues(`conditions`)[index];
    const dataLayerCondition = conditionFromQuery(
      undefined,
      condition as DirectSearchConditionResponse
    );

    if (condition.direct_mail) {
      push({
        event: 'emailSettings',
        actionType: 'email_settings',
        actionName: 'delivery_settings',
        ...dataLayerCondition,
      });
    } else {
      push({
        event: 'emailSettings',
        actionType: 'email_settings',
        actionName: 'unsubscribe',
        ...dataLayerCondition,
      });
    }

    if (condition) {
      DirectAPI.patchDirectDirectMailId(condition.id, {
        direct_mail: condition.direct_mail as boolean,
      })
        .then(() => {
          dispatch(notificationSuccess('保存しました。'));
        })
        .catch((error: AxiosError<ResponseError>) => {
          if (error.response) {
            dispatch(notificationError(error.response.data.error_message));
          } else {
            dispatch(notificationError('保存に失敗しました'));
          }
        });
    }
  };

  // テストデータ
  // const setTestData = () => {
  //   setConditions(testConditionsData());
  // };

  return {
    conditions,
    onDrop,
    onRemove,
    onClickEditButton,
    onClickSearchButton,
    onChangeDirectMail,
    methods,
    fields,
    loaded,
  };
};
