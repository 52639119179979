import React from 'react';

import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { PLACEHOLDER } from '@/definition/PLACEHOLDER';
import { TSectionProps } from '@/pages/Resume/Edit';

export const ResumeFormSectionAddress = ({ id }: TSectionProps): React.ReactElement => {

  return (
    <div id={id}>
      <TitleSectionTitle>
        <div className="flex items-center">
          <span className="mr-4">現住所</span>
          <TooltipMessageMatchbou
            className=""
            message="オファー機能ご利用時、「郵便番号」「住所」「住所ふりがな」「電話番号」「メールアドレス」は企業に開示されません。"
            position={['0', '-94px']}
          >
            <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
          </TooltipMessageMatchbou>
        </div>
      </TitleSectionTitle>
      <div className="mbx-utils-stack-v--16">
        <FormLayoutFieldset>
          <FormLabel label="郵便番号" type="small"></FormLabel>
          <FormContainerTextfield
            name="postal_code"
            className="w-150 mkt_mask_items"
            placeholder={PLACEHOLDER.postal_code}
            type="number"
          />
        </FormLayoutFieldset>
        <FormLayoutFieldset>
          <FormLabel label="住所" type="small"></FormLabel>
          <FormContainerTextfield
            name="address2"
            className="mkt_mask_items"
            placeholder={PLACEHOLDER.address2}
          />
        </FormLayoutFieldset>
        <FormLayoutFieldset>
          <FormLabel label="住所ふりがな" type="small"></FormLabel>
          <FormContainerTextfield
            name="address1"
            className="mkt_mask_items"
            placeholder={PLACEHOLDER.address1}
          />
        </FormLayoutFieldset>
        <FormLayoutFieldset>
          <FormLabel label="メールアドレス" type="small"></FormLabel>
          <FormContainerTextfield
            name="email"
            className="mkt_mask_items"
            placeholder={PLACEHOLDER.email}
          />
        </FormLayoutFieldset>
        <FormLayoutFieldset>
          <FormLabel label="電話番号" type="small">
            <FormCaption></FormCaption>
          </FormLabel>
          <FormContainerTextfield name="tel" readOnly={true} className="mkt_mask_items" />
        </FormLayoutFieldset>
      </div>
    </div>
  );
};

const FormCaption = (): React.ReactElement => {
  return (
    <>
      <ButtonTextLink href="/mypage/profile">基本情報</ButtonTextLink>から編集できます
    </>
  );
};
