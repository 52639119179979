import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { BlockCompany, BlockCompaniesPatchParam } from '@/utils/api-client';
import Pager from '@/components/common/Navigation/Page/Pager';
import BaseButton from '../Button/BaseButton';

type Props = {
  total: number;
  companies: BlockCompany[];
  blockCompanies: BlockCompany[];
  page: number;
  pageLength: number;
  searchEnd: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setBlockComplete: React.Dispatch<React.SetStateAction<boolean>>;
  patchCompanies: (payload: BlockCompaniesPatchParam) => Promise<void>;
};

type TCheckBox = {
  [key: string]: boolean;
};

function CompanyCheckForm(props: Props): React.ReactElement {
  const [defaultValue, setDefault] = useState<TCheckBox>();
  const [checkedBox, setCheckedBox] = useState<string[]>([]);

  useEffect(() => {
    const obj = Object.assign(
      {},
      ...props.companies.map((item) => ({
        [item.id]: false,
      }))
    );
    setDefault(obj);
  }, [props.companies]);

  const methods = useForm<TCheckBox>({
    defaultValues: defaultValue,
  });

  const { handleSubmit, watch } = methods;

  const checked = (id: string) => {
    if (!watch(id)) return false;
    return watch(id);
  };

  const onChange = (id: string, checked: boolean) => {
    const box = checkedBox;
    if (checked) box?.push(id);
    if (!checked) {
      const index = box.indexOf(id);
      box.splice(index, 1);
    }

    setCheckedBox(box);
  };

  const onBlock = async (data: TCheckBox) => {
    const payload: BlockCompaniesPatchParam = {
      ids: Object.keys(data).filter((key) => data[key] === true),
    };

    const defaultBlock = props.blockCompanies.map((b) => {
      return b.id;
    });
    for (let i = 0; i < defaultBlock.length; i++) {
      payload?.ids?.push(defaultBlock[i]);
    }

    props.patchCompanies(payload);
  };

  return (
    <section className="text-left">
      {props.total !== 0 && (
        <p className="mbx-typography--body_2 mt-24">
          {props.page === 0 ? props.page + 1 : props.page + '1'}〜
          {props.page === props.pageLength - 1 ? props.total : props.page + 1 + '0'} / {props.total}
          件
        </p>
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => onBlock(data))}>
          {props.companies &&
            props.companies.map((company, index) => {
              return (
                <div key={index}>
                  <label
                    className="mbx-formCheckbox relative"
                    onChange={() => onChange(company.id.toString(), checked(company.id.toString()))}
                  >
                    <input
                      type="checkbox"
                      checked={checked(company.id.toString())}
                      {...methods.register(company.id.toString())}
                    />
                    <span className="text-body_1 pt-12 pl-32 mkt_mask_items">
                      {company.name}
                      <i className="mbx-icon mbx-icon-Check"></i>
                    </span>
                    <span className="text-body_1 text-gray-600 pb-12 pl-32">
                      {company.address}
                    </span>
                  </label>
                </div>
              );
            })}
          {props.total !== 0 && (
            <Pager pageLength={props.pageLength} page={props.page} setPage={props.setPage} />
          )}
          {props.searchEnd && (
            <div className="m-auto mt-48 text-center">
              <BaseButton
                theme="primary"
                size="m"
                className="send-btn"
                disabled={checkedBox.length === 0}
              >
                チェックした企業をブロックする
              </BaseButton>
            </div>
          )}
        </form>
      </FormProvider>
    </section>
  );
}

export default CompanyCheckForm;
