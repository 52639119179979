import React, { MouseEventHandler, useEffect, useState, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ButtonIconButton } from '../Button/IconButton';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';

type TProps = {
  show: boolean;
  children: string | null;
  onClick?: MouseEventHandler<HTMLSpanElement>;
};

/**
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=5332%3A102628
 */
export const NotificationError = ({
  children,
  show = false,
  onClick,
}: TProps): React.ReactElement => {
  // !see https://github.com/reactjs/react-transition-group/issues/668
  const nodeRef = React.useRef(null);

  const [showState, setShow] = useState(show);
  const callBacks = {
    onEnter: () => console.log('show error'),
    onExited: () => console.log('hide error'),
  };
  const { push } = useContext(DataLayerContext);

  useEffect(() => {
    setShow(show);
    if (show) {
      if (Array.isArray(children)) {
        children.forEach((child) => {
          push({
            event: 'errorMessage',
            actionType: "error_message",
            actionName: child ? child.replace(/\r?\n/g, ' ') : '',
          });
        });
      } else {
        push({
          event: 'errorMessage',
          actionType: "error_message",
          actionName: children ? children.replace(/\r?\n/g, ' ') : '',
        });
      }
    }
  }, [show]);
  return (
    <CSSTransition
      in={showState}
      timeout={550}
      nodeRef={nodeRef}
      {...callBacks}
      unmountOnExit
      classNames="mbx-anime-notification"
    >
      <div ref={nodeRef} className={'mbx-notification mbx-notification--error'}>
        <div className="mbx-notification-inner">
          <div className={'mbx-notification-message'}>{children}</div>
          <div className={'mbx-notification-icon'}>
            <ButtonIconButton
              type="white"
              iconName="Menu-close"
              hitArea="mini"
              onClick={onClick}
            ></ButtonIconButton>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
