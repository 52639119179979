import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Container, Draggable, OnDropCallback } from 'react-smooth-dnd';

import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { CareerQualificationEmpty } from '@/components/common/CareerQualification/Empty';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { FormLayoutUnitDivider } from '@/components/common/Form/Layout/UnitDivider';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { TSectionProps } from '@/pages/Resume/Edit';
import { QualificationQTypeEnum } from '@/utils/api-client';
import { dataStringSplitter } from '@/utils/string';

type TFormValues = {
  qualifications: {
    q_id: string;
    q_type: QualificationQTypeEnum; // これでTOEICとかを判定？
    q_title: string;
    q_date: string;
    q_score: string;
    q_visible?: boolean;
  }[];
};

// TODO 仕様待ち : 利用してない項目の扱いについて
export const ResumeFormSectionQualification = ({ id }: TSectionProps): React.ReactElement => {
  const ref = useRef(true);

  const { control } = useFormContext<TFormValues>();

  const { fields, move } = useFieldArray({
    control,
    name: 'qualifications',
  });

  /**
   * react-smooth-dnd の onDrop で発火
   * このイベントで渡される引数によって元々どのインデックスの要素が消えて、どのインデックスに差し込まれたのか判定
   */
  const onDrop: OnDropCallback = ({ removedIndex, addedIndex }) => {
    if (removedIndex !== null && addedIndex !== null) {
      move(removedIndex, addedIndex);
    }
  };

  /**
   * 順序変更を反映
   */
  useEffect(() => {
    // 初回レンダリング時はrefをfalseにして、return。
    if (ref.current) {
      ref.current = false;
      return;
    }
  }, [fields]);

  return (
    <div id={id}>
      <TitleSectionTitle>資格・免許</TitleSectionTitle>

      {fields.length ? (
        <>
          <p className="text-caption_1 mb-24">
            <ButtonTextLink href="/mypage/cv/edit#qualification">職務経歴書</ButtonTextLink>
            から編集できます。（履歴書では並べ替えのみ編集可能）
          </p>
          <div className="mbx-keyline-horizontal-b--thin">
            <Container
              onDrop={onDrop}
              dragHandleSelector=".dragHandle"
              render={(ref) => {
                return (
                  <div ref={ref}>
                    {fields.map((item, index) => {
                      return (
                        <Draggable key={item.id}>
                          <FormLayoutFieldset>
                            <></>
                            <div className="flex mbx-keyline-horizontal--thin items-center">
                              <div
                                className={cn(
                                  'flex',
                                  'flex-auto',
                                  'sm:hidden',
                                  'mkt_mask_items',
                                  {
                                    'opacity-20': !item.q_visible,
                                  }
                                )}
                              >
                                <FormLayoutUnitDivider>
                                  {dataStringSplitter(item.q_date)[0]}年
                                </FormLayoutUnitDivider>
                                <FormLayoutUnitDivider className="mr-22">
                                  {dataStringSplitter(item.q_date)[1]}月
                                </FormLayoutUnitDivider>
                                <FormLayoutUnitDivider className="mr-4">
                                  {item.q_type === 'text' && (
                                    item.q_title
                                  )}
                                  {item.q_type === 'toeic' && (
                                    'TOEIC'
                                  )}
                                  {item.q_type === 'toefl' && (
                                    'TOEFL'
                                  )}
                                </FormLayoutUnitDivider>
                                {item.q_type !== 'text' && (
                                  <FormLayoutUnitDivider className="flex-auto">
                                    {item.q_score} 点取得
                                  </FormLayoutUnitDivider>
                                )}
                              </div>
                              <div
                                className={cn('lg:hidden text-subhead_1 my-16 flex-auto', {
                                  'opacity-20': !item.q_visible,
                                })}
                              >
                                {dataStringSplitter(item.q_date)[0]}年
                                {dataStringSplitter(item.q_date)[1]}月
                                <br />
                                {item.q_type === 'text'
                                  ? item.q_title
                                  : `${item.q_title} ${item.q_score} 点取得`}
                              </div>
                              <div className="flex">
                                <ButtonIconButton
                                  type="gray"
                                  iconName="Sortable"
                                  className={cn({
                                    dragHandle: item.q_visible,
                                    'opacity-20': !item.q_visible,
                                  })}
                                  focus={false}
                                ></ButtonIconButton>
                              </div>
                            </div>
                          </FormLayoutFieldset>
                        </Draggable>
                      );
                    })}
                  </div>
                );
              }}
            ></Container>
          </div>
        </>
      ) : (
        <CareerQualificationEmpty type="qualification"></CareerQualificationEmpty>
      )}
    </div>
  );
};
