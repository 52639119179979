import '@/styles/common/Direct/MyNaviSelectForm.scss';

import { AxiosError } from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { FormProvider, useForm, UseFormProps } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { FormContainerDropdown } from '@/components/common/Form/Container/Dropdown';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import Modal from '@/components/common/Modal';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { CAREER_HISTORY_TEMPLATE } from '@/definition/CAREER_HISTORY_TEMPLATE';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import {
    notificationError, setDataLayer, setDataLayerChangedBy, toggleLoading
} from '@/redux/index';
import { State } from '@/redux/state';
import { MyNavi, MyNaviApi, ResponseError } from '@/utils/api-client';
import { getOptionChildrenFromValue } from '@/utils/optionValueToChildren';
import { progressFromMyNavi } from '@/utils/utils';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { yupResolver } from '@hookform/resolvers/yup';

import BaseButton from '../Button/BaseButton';
import { FormContainerDropdownMultiple } from '../Form/Container/DropdownMultiple';
import { FormContainerTextArea } from '../Form/Container/TextArea';

type Props = {
  children?: React.ReactElement;
  cancelButton?: boolean;
  afterSending?: () => void;
  onChangeForm?: (formValue: MyNavi) => void;
  offer?: boolean; // オファー登録ページかどうか
};

type MyNaviKey = keyof MyNavi;

export function MyNaviSelectForm({
  children,
  cancelButton,
  afterSending,
  onChangeForm,
  offer,
}: Props): React.ReactElement {
  const mq = useMBXMediaQuery();
  const dispatch = useDispatch();
  const user = useSelector((state: State) => state.user);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [template, setTemplate] = useState<boolean>(false);
  const [updatedKey, setUpdatedKey] = useState<MyNaviKey[]>([]);
  const [getMyNavi, setGetMyNavi] = useState<MyNavi>({
    mc_connected: false,
    mc_experienced_job: 0,
    mc_experienced_year: 0,
    mc_companies_count: 0,
    mc_yearly_income: 0,
    mc_situation: 0,
    mc_preferred_job_type: [],
    mc_preferred_business_type: 0,
    mc_preferred_yearly_income: 0,
    mc_preferred_prefecture: [],
    mc_preferred_change_date: 0,
    mc_preferred_reason: '',
    mc_maximum_management_count: 0,
    mc_english_skills: 0,
    mc_final_education: 0,
    mc_description: '',
    mc_preferred_employment_status: [],
    mc_conditions: [],
    mc_preferred_conditions: '',
  });

  useEffect(() => {
    dispatch(toggleLoading(true));
    (async () => {
      try {
        const res = await new MyNaviApi().getMynaviCreator();
        setGetMyNavi(res.data.mynavi_creator);
        setDefaultValues(res.data.mynavi_creator);
      } catch (error) {
        const e = error as AxiosError<ResponseError>;
        if (e.response) dispatch(notificationError(e.response.data.error_code));
      } finally {
        setUpdatedKey([]);
        dispatch(toggleLoading(false));
      }
    })();
  }, [user]);

  // データを取得した後にRHFに値をsetValueする
  const setDefaultValues = (res: MyNavi) => {
    const requiredItem: (keyof MyNavi)[] = [
      "mc_experienced_job",
      "mc_experienced_year",
      "mc_final_education",
      "mc_companies_count",
      "mc_yearly_income",
      "mc_preferred_yearly_income",
      "mc_preferred_change_date",
    ];
    (Object.keys(res) as (keyof MyNavi)[]).forEach((v) => {
      if (res?.[v] !== undefined) {
        if(requiredItem.includes(v) && res?.[v] === 0) {
          methods.setValue(v, undefined);
        } else {
          methods.setValue(v, res?.[v]);
        }
      }
    });
  };

    /**
   * validation 設定
   */
    const validationObject: any = {
      mc_experienced_job: YUP_VALIDATION.required,
      mc_experienced_year: YUP_VALIDATION.required,
      mc_yearly_income: YUP_VALIDATION.required,
      mc_companies_count: YUP_VALIDATION.required,
      mc_final_education: YUP_VALIDATION.required,
      mc_preferred_job_type: YUP_VALIDATION.array_required,
      mc_preferred_yearly_income: YUP_VALIDATION.required,
      mc_preferred_change_date: YUP_VALIDATION.required,
      mc_preferred_employment_status: YUP_VALIDATION.array_required,
    };

    // オファー登録ページは職歴概要も必須にする
    if (offer) validationObject['mc_description'] =  YUP_VALIDATION.required;

     const schema = yup.object().shape(validationObject);

  // TODO : RFH をアップデートしたらMyNaviの型付でTSエラーになったので暫定的にanyに
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const useFormProps: UseFormProps<any> = {
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      mc_connected: false,
      mc_experienced_job: undefined,
      mc_experienced_year: undefined,
      mc_companies_count: undefined,
      mc_yearly_income: undefined,
      mc_situation: undefined,
      mc_preferred_job_type: [],
      mc_preferred_business_type: undefined,
      mc_preferred_yearly_income: undefined,
      mc_preferred_prefecture: [],
      mc_preferred_change_date: undefined,
      mc_preferred_reason: '',
      mc_maximum_management_count: undefined,
      mc_english_skills: undefined,
      mc_final_education: undefined,
      mc_description: '',
      mc_preferred_employment_status: [],
      mc_conditions: [],
      mc_preferred_conditions: '',
    },
  };

  const methods = useForm(useFormProps);

  const { handleSubmit, watch } = methods;

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      //更新があったkeyを保存する
      setUpdatedKey((prevState) => {
        if (prevState.indexOf(name as MyNaviKey) >= 0) return prevState;
        const newState: MyNaviKey[] = [...prevState, name as MyNaviKey];
        return newState;
      });
      if (onChangeForm) {
        onChangeForm(methods.getValues());
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  // 送信処理
  const onSubmit = async (data: MyNavi) => {
    if (!getMyNavi) return;

    dispatch(toggleLoading(true));

    try {
      await new MyNaviApi().patchMynaviCreator(convertedRequest(data));
      if (
        getMyNavi.mc_preferred_change_date !== data.mc_preferred_change_date || // {転職希望時期}
        progressFromMyNavi(getMyNavi) !== progressFromMyNavi(data) // {経験・希望条件完成度}
      ) {
        dispatch(setDataLayerChangedBy('mynavi_select_form'));
        dispatch(
          setDataLayer({
            timeToCareerChange: getOptionChildrenFromValue(
              'change_date',
              data?.mc_preferred_change_date
            ),
            conditionCompleteness: `${data ? progressFromMyNavi(data) : 0}`,
          })
        );
      }
      setGetMyNavi(data);
      if (afterSending) {
        afterSending();
      }
    } catch (e) {
      dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));

      setUpdatedKey([]);
    }
  };

  // formからデータ取る際に文字列で返ってくるので調整してリクエスト
  const convertedRequest = (data: MyNavi): MyNavi => {
    //更新があったkeyのみを変換して利用する
    const convertedData: any = {};
    updatedKey.forEach((key) => {
      switch (key) {
        case 'mc_connected':
          convertedData[key] = data[key];
          break;
        case 'mc_preferred_reason':
          convertedData[key] = data[key];
          break;
        case 'mc_description':
          convertedData[key] = data[key];
          break;
        case 'mc_preferred_prefecture':
          convertedData[key] =
            data.mc_preferred_prefecture && data.mc_preferred_prefecture.map((s) => Number(s));
          break;
        case 'mc_preferred_job_type':
          convertedData[key] =
            data.mc_preferred_job_type && data.mc_preferred_job_type.map((s) => Number(s));
          break;
        case 'mc_preferred_employment_status':
          convertedData[key] =
            data.mc_preferred_employment_status && data.mc_preferred_employment_status.map((s) => Number(s));
          break;
        case 'mc_conditions':
          convertedData[key] =
            data.mc_conditions && data.mc_conditions.map((s) => Number(s));
          break;
        case 'mc_preferred_conditions':
          convertedData[key] = data[key];
            break;
        default:
          convertedData[key] = Number(data[key]);
      }
    });
    return convertedData as MyNavi;
  };

  const templateMethods = useForm<{template: string}>({
    defaultValues: {
      template: CAREER_HISTORY_TEMPLATE.template_job[0].value,
    }
  });

  useEffect(() => {
    const subscription = templateMethods.watch((value, { name }) => {
      if (!name || !descriptionRef.current) return;
      descriptionRef.current.textContent = CAREER_HISTORY_TEMPLATE.template[value[name]];
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  return (
    <section className="condition-selection w-624 m-auto sm:w-full">
      <FormProvider {...methods}>
        <form id="myNaviSelectForm" onSubmit={handleSubmit((data) => onSubmit(data))}>
          <div className="">
            <FormLayoutFieldset>
              <FormLabel label="経験職種" type="small">
                <div className="flex items-center">
                  <TooltipMessageMatchbou
                    className="-ml-12"
                    message="キャリアの中で一番経験の長い職種を選択してください。"
                    position={['0', '-94px']}
                  >
                    <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
                  </TooltipMessageMatchbou>
                  <p className="text-11_12 text-error-700">＊</p>
                </div>
              </FormLabel>
              <FormContainerDropdown
                className="select mkt_mask_items"
                name="mc_experienced_job"
                placeholder="選択してください"
                selectList={FORM_OPTIONS.preferred_job_type}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>
          <div className="mt-16">
            <FormLayoutFieldset>
              <FormLabel label="上記で選択した職種の経験年数" type="small">
                <p className="text-11_12 -ml-12 text-error-700">＊</p>
              </FormLabel>
              <FormContainerDropdown
                className="select mkt_mask_items"
                name="mc_experienced_year"
                placeholder="選択してください"
                selectList={FORM_OPTIONS.experienced_year}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>
          <div className="mt-16">
            <FormLayoutFieldset>
              <FormLabel label="現在の就業状況" type="small"></FormLabel>
              <FormContainerDropdown
                className="select mkt_mask_items"
                name="mc_situation"
                placeholder="選択してください"
                selectList={FORM_OPTIONS.situation}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>
          <div className="mt-16">
            <FormLayoutFieldset>
              <FormLabel label="現在の年収" type="small">
                <p className="text-11_12 -ml-12 text-error-700">＊</p>
              </FormLabel>
              <FormContainerDropdown
                className="select mkt_mask_items"
                name="mc_yearly_income"
                placeholder="選択してください"
                selectList={FORM_OPTIONS.yearly_income}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>
          <div className="mt-16">
            <FormLayoutFieldset>
              <FormLabel label="これまでの経験社数" type="small">
                <div className="flex items-center">
                  <TooltipMessageMatchbou
                    className="-ml-12"
                    message="キャリアの中で経験した総数を選択してください。"
                    position={['0', '-94px']}
                  >
                    <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
                  </TooltipMessageMatchbou>
                  <p className="text-11_12 text-error-700">＊</p>
                </div>
              </FormLabel>
              <FormContainerDropdown
                className="select mkt_mask_items"
                name="mc_companies_count"
                placeholder="選択してください"
                selectList={FORM_OPTIONS.companies_count}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>
          <div className="mt-16">
            <FormLayoutFieldset>
              <FormLabel label="最大マネジメント人数" type="small"></FormLabel>
              <FormContainerDropdown
                className="select mkt_mask_items"
                name="mc_maximum_management_count"
                placeholder="選択してください"
                selectList={FORM_OPTIONS.maximum_management_count}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>
          <div className="mt-16">
            <FormLayoutFieldset>
              <FormLabel label="最終学歴" type="small">
                <p className="text-11_12 -ml-12 text-error-700">＊</p>
              </FormLabel>
              <FormContainerDropdown
                className="select mkt_mask_items"
                name="mc_final_education"
                placeholder="選択してください"
                selectList={FORM_OPTIONS.final_education}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>
          <div className="mt-16">
            <FormLayoutFieldset>
              <FormLabel label="英語力" type="small"></FormLabel>
              <FormContainerDropdown
                className="select mkt_mask_items"
                name="mc_english_skills"
                placeholder="選択してください"
                selectList={FORM_OPTIONS.english_skills}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>
          <div className="mt-16">
            <FormLayoutFieldset>
              <FormLabel label="希望職種" type="small">
                <p className="text-11_12 -ml-12 text-error-700">＊</p>
              </FormLabel>
              <FormContainerDropdownMultiple
                className="select mkt_mask_items"
                name="mc_preferred_job_type"
                placeholder="選択してください（複数選択可）"
                selectList={FORM_OPTIONS.experienced_job}
              ></FormContainerDropdownMultiple>
            </FormLayoutFieldset>
          </div>
          <div className="mt-16">
            <FormLayoutFieldset>
              <FormLabel label="希望業種" type="small"></FormLabel>
              <FormContainerDropdown
                className="select mkt_mask_items"
                name="mc_preferred_business_type"
                placeholder="選択してください"
                selectList={FORM_OPTIONS.business_type}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>
          <div className="mt-16">
            <FormLayoutFieldset>
              <FormLabel label="希望年収" type="small">
                <p className="text-11_12 -ml-12 text-error-700">＊</p>
              </FormLabel>
              <FormContainerDropdown
                className="select mkt_mask_items"
                name="mc_preferred_yearly_income"
                placeholder="選択してください"
                selectList={FORM_OPTIONS.yearly_income}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>
          <div className="mt-16">
            <FormLayoutFieldset>
              <FormLabel label="希望勤務地" type="small"></FormLabel>
              <FormContainerDropdownMultiple
                className="select mkt_mask_items"
                name="mc_preferred_prefecture"
                placeholder="選択してください（複数選択可）"
                selectList={FORM_OPTIONS.prefecture}
              ></FormContainerDropdownMultiple>
            </FormLayoutFieldset>
          </div>
          <div className="mt-16">
            <FormLayoutFieldset>
              <FormLabel label="転職希望時期" type="small">
                <p className="text-11_12 -ml-12 text-error-700">＊</p>
              </FormLabel>
              <FormContainerDropdown
                className="select mkt_mask_items"
                name="mc_preferred_change_date"
                placeholder="選択してください"
                selectList={FORM_OPTIONS.change_date}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>
          <div className="mt-16">
            <FormLayoutFieldset>
              <FormLabel label="希望の雇用形態・働き方" type="small">
                <p className="text-11_12 -ml-12 text-error-700">＊</p>
              </FormLabel>
              <FormContainerDropdownMultiple
                className="select mkt_mask_items"
                name="mc_preferred_employment_status"
                placeholder="選択してください（複数選択可）"
                selectList={FORM_OPTIONS.employment_status}
              ></FormContainerDropdownMultiple>
            </FormLayoutFieldset>
          </div>
          <div className="mt-16">
            <FormLayoutFieldset>
              <FormLabel label="こだわりの条件" type="small"></FormLabel>
              <FormContainerDropdownMultiple
                className="select mkt_mask_items"
                name="mc_conditions"
                placeholder="選択してください（3つまで選択可）"
                selectList={FORM_OPTIONS.conditions}
                limit={3}
              ></FormContainerDropdownMultiple>
            </FormLayoutFieldset>
          </div>
          <div className="mt-16">
            <FormLayoutFieldset>
              <FormLabel label="その他の希望条件" type="small"></FormLabel>
              <p className="-mt-6 mb-6 text-11_20">
                上記以外で希望の条件があればご自由にご入力ください。
              </p>
              <FormContainerTextArea
                className="mkt_mask_items"
                name="mc_preferred_conditions"
                placeholder="例）地方在住のためフルリモート勤務を希望します。／副業をしているため両立できるお仕事を探しています。"
                maxLength={100}
                rows={mq.sm ? 10 : 3}
              />
            </FormLayoutFieldset>
          </div>
          {offer ? (
            <div>
              <FormLayoutFieldset>
                <FormLabel label="職務経歴概要" type="small">
                  <div className="flex items-center -ml-12">
                    <TooltipMessageMatchbou
                      className=""
                      message="オファー機能ご利用時、「職務経歴概要」は企業に開示されます。そのため自作のポートフォリオサイトなどのURLを入力すると、リンク先のページも企業は閲覧できます。氏名などの個人情報を企業に開示したくない場合は、ご自身で必ずリンク先のページを確認・修正の上、ご入力をお願いします。"
                      position={['0', '-94px']}
                      widthClassName="w-376"
                    >
                      <ButtonIconButton
                        hitArea="mini"
                        iconName="Info"
                        type="gray"
                        focus={false}
                      />
                    </TooltipMessageMatchbou>
                    <p className="text-11_12 text-error-700">＊</p>
                    <ButtonTextLink
                      onClick={(e) => {
                        e.preventDefault();
                        templateMethods.setValue("template", CAREER_HISTORY_TEMPLATE.template_job[0].value);
                        setTemplate(true);
                      }}
                      className="text-11_12 ml-16"
                      bold
                    >
                      テンプレートから⼊⼒する
                    </ButtonTextLink>
                  </div>
                </FormLabel>
                <FormContainerTextArea
                  className="mkt_mask_items"
                  name="mc_description"
                  placeholder="例）専門学校を卒業し、株式会社マッチワークスに入社。Webデザイナーとして受託案件のWeb制作に4年間従事。ページデザインやHTML、CSS、JavaScript、PHPでのサイト構築を一通り経験した後、UI/UX領域にスキルの幅を広げるため、株式会社マッチラボに転職。現在は、Webデザイナー経験を活かしながら、アプリ開発のUI/UXデザイナーに従事しています。"
                  maxLength={300}
                  rows={mq.sm ? 12 : 6}
                />
              </FormLayoutFieldset>
            </div>
          ) : (
            <div>
              <FormLayoutFieldset>
                <FormLabel label="備考" type="small">
                  <TooltipMessageMatchbou
                    className="-ml-12"
                    message="オファー機能ご利用時、この項目は企業に開示されません。マイナビワークスが転職支援を行う際の参考にさせていただきます。"
                    position={['0', '-94px']}
                  >
                    <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
                  </TooltipMessageMatchbou>
                </FormLabel>
                <p className="-mt-6 mb-6 text-11_20">
                  作成済みポートフォリオのURLやキャリアアドバイザーに伝えたいことなどご自由にご入力ください。
                </p>
                <FormContainerTextArea
                  className="mkt_mask_items"
                  name="mc_preferred_reason"
                  placeholder="例）MATCHBOXでもポートフォリオを作成しましたが、元々自身で作成していたポートフォリオは「https://□□□□□.com」となります。これまでに培ったWebデザインやUI/UX領域のスキルは活かしつつ、今後はよりプロデュース的立場の上流工程にキャリアをアップさせていきたいと考えています。"
                  maxLength={300}
                  rows={mq.sm ? 10 : 3}
                />
              </FormLayoutFieldset>
            </div>
          )}
          {children}
        </form>
      </FormProvider>
      {cancelButton && (
        <div className="text-center mt-24">
          <BaseButton size={'m'} theme={'link'} href={'/mypage'}>
            登録をキャンセル
          </BaseButton>
        </div>
      )}
      <Modal
        isOpen={template}
        isClose={() => setTemplate(false)}
        className="rounded-modalContainer"
      >
        <section className="max-w-784 w-784 md:w-screen sm:w-screen sm:h-auto overflow-hidden pt-48 px-24">
          <div className={cn('font-bold mt-20', mq.sm ? 'text-18_33' : 'text-21_34')}>
            <p className="mt-48">
              職務経歴概要テンプレート
            </p>
          </div>
          <div>
            <p className="mt-14 text-15_32">
              使⽤したいテンプレートを選択してください。
              <p className="mt-7 text-12_20">※テンプレートを挿⼊すると、すでに⼊⼒された内容は上書きされます。</p>
            </p>
            <FormProvider {...templateMethods}>
              <form>
              <div className="mt-26 flex justify-center">
                <FormLayoutFieldset
                  className={cn(mq.sm ? 'w-308' : 'w-530')}
                >
                  <FormContainerDropdown
                    className={cn('select mkt_mask_items', mq.sm ? 'w-308' : 'w-530')}
                    name="template"
                    selectList={CAREER_HISTORY_TEMPLATE.template_job}
                  ></FormContainerDropdown>
                  <div className="mkt_mask_items mt-26 rounded-4 w-full text-14_21 mbx-formContainer--textarea text-left bg-gray-100" ref={descriptionRef}>{CAREER_HISTORY_TEMPLATE.template.creative}</div>
                </FormLayoutFieldset>
              </div>
                <div className="pb-20 mb-48 sm:mb-72">
                  <div className="w-308 m-auto mt-48">
                    <BaseButton
                      theme="primary"
                      size="m"
                      className="w-full"
                      onClick={(e) => {
                        e.preventDefault();
                        methods.setValue("mc_description", descriptionRef.current?.textContent);
                        setTemplate(false);
                      }}
                    >
                      このテンプレートを使って⼊⼒する
                    </BaseButton>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </section>
      </Modal>
    </section>
  );
}
