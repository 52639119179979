import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Container, Draggable, OnDropCallback } from 'react-smooth-dnd';

import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { CareerUneditable } from '@/components/common/Career/Uneditable';
import { CareerQualificationEmpty } from '@/components/common/CareerQualification/Empty';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { TSectionProps } from '@/pages/Resume/Edit';

type TFormValues = {
  careers: {
    c_id: string;
    c_company_name: string;
    c_join_date: string;
    c_leave_date: string;
    c_visible?: boolean;
  }[];
};

// TODO 仕様待ち : 利用してない項目の扱いについて c_leave_reason c_position 等
export const ResumeFormSectionCareer = ({ id }: TSectionProps): React.ReactElement => {
  const ref = useRef(true);

  const { control } = useFormContext<TFormValues>();

  const { fields, move, update } = useFieldArray({
    control,
    name: 'careers',
  });

  /**
   * react-smooth-dnd の onDrop で発火
   * このイベントで渡される引数によって元々どのインデックスの要素が消えて、どのインデックスに差し込まれたのか判定
   */
  const onDrop: OnDropCallback = ({ removedIndex, addedIndex }) => {
    if (removedIndex !== null && addedIndex !== null) {
      move(removedIndex, addedIndex);
    }
  };

  /**
   * 順序変更を反映
   */
  useEffect(() => {
    // 初回レンダリング時はrefをfalseにして、return。
    if (ref.current) {
      ref.current = false;
      return;
    }
  }, [fields]);

  return (
    <div id={id}>
      <TitleSectionTitle>職歴</TitleSectionTitle>
      {fields.length ? (
        <>
          <p className="text-caption_1 mb-24">
            <ButtonTextLink href="/mypage/cv/edit#SECTION_CAREERS">職務経歴書</ButtonTextLink>
            から編集できます。（履歴書では並べ替えのみ編集可能）
          </p>

          <Container
            onDrop={onDrop}
            dragHandleSelector=".dragHandle"
            render={(ref) => {
              return (
                <div ref={ref} className="mb-8 mbx-utils-stack-v--16">
                  {fields.map((item, index) => {
                    const period = `${item.c_join_date}〜${item.c_leave_date}`;
                    return (
                      <Draggable key={item.id}>
                        <CareerUneditable
                          index={index + 1}
                          body={item.c_company_name}
                          period={period}
                          dragHandle={cn({ dragHandle: item.c_visible })}
                          invisible={!item.c_visible}
                        ></CareerUneditable>
                      </Draggable>
                    );
                  })}
                </div>
              );
            }}
          ></Container>
        </>
      ) : (
        <CareerQualificationEmpty type="career"></CareerQualificationEmpty>
      )}
    </div>
  );
};
