import React from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { WorksModule } from '@/components/common/Portfolio/';
import { ContentsBlank } from '@/components/common/ContentsBlank';
import { PortfolioItem } from '@/utils/api-client';

type Props = {
  portfolioItems: PortfolioItem[] | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleDragEnd: ({ active, over }: any) => void;
  removeModalOpen: (index: number) => void;
  onVisible: (id: number) => void;
  linkTo: (id: number) => void;
  setPortfolioTypeModal: (value: React.SetStateAction<boolean>) => void;
};

export const WorkListSection = (props: Props): React.ReactElement => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  return (
    <>
      <section className="portfolio-list">
        {props.portfolioItems && (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={props.handleDragEnd}
            autoScroll={false}
          >
            <SortableContext
              items={Array.from(props.portfolioItems.values()).map((item) => ({
                id: `${item.i_id}`,
              }))}
              strategy={rectSortingStrategy}
            >
              <div className="smooth-dnd-container">
                {Array.from(props.portfolioItems.values()).map((item, index) => (
                  <WorksModule
                    key={item.i_id}
                    id={`${item.i_id}`}
                    thumbnail={item.i_image ? item.i_image.f_thumbnail : ''}
                    title={item.i_title}
                    category={item.i_tags ? item.i_tags.join(', ') : ''}
                    editMode={true}
                    moveClass={'dragHandleSelector'}
                    visible={item.i_visible}
                    removeClick={() => props.removeModalOpen(index)}
                    onVisible={() => props.onVisible(item.i_id)}
                    onClick={() => props.linkTo(item.i_id)}
                  />
                ))}
                <div
                  className="add-portfolio flex flex-col justify-center items-center h-242"
                  onClick={() => props.setPortfolioTypeModal(true)}
                >
                  <i className="mbx-icon mbx-icon-Large32_Plus text-32_32"></i>
                  <p className="text-11_16 pt-8 font-medium text-blue-700">作品を追加する</p>
                </div>
              </div>
            </SortableContext>
          </DndContext>
        )}
      </section>
    </>
  );
};
