import React, { useMemo, useState } from 'react';
import Cropper from 'react-cropper';

import BaseButton from '@/components/common/Button/BaseButton';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { DataLayerPushContainer } from '../DataLayerPushContainer';

type TProps = {
  src: string | ArrayBuffer | null | undefined; // Cropperに渡せるのはstringだけなんだが呼び出し元でいろんな型で指定されちゃってるのでとりあえずとり得る型を列挙する
  width: number;
  height: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postImage?: (data: any) => void;
  onSave?: (dataUri: string) => void;
  onCancel?: React.MouseEventHandler<HTMLDivElement>;
};

export const ImageCropperComponent = ({
  src,
  width,
  height,
  postImage,
  onSave,
  onCancel,
}: TProps): React.ReactElement => {
  const mq = useMBXMediaQuery();
  const [cropperRef, setCropperRef] = useState<Cropper>();
  const [zoom, setZoom] = useState(0.5);

  const maxHeight = useMemo(() => {
    if (mq.lg) {
      return 584;
    }
    if (mq.md) {
      return 584;
    }
    if (mq.sm) {
      return 279;
    }
  }, [mq]);

  const preferredSizeCheck = (event:Cropper.ReadyEvent<HTMLImageElement>)=>{
    if (!event) return;
    const imageData = event.currentTarget.cropper.getImageData();
    const cropData = event.currentTarget.cropper.getCropBoxData();
    if(imageData.naturalHeight === height && imageData.naturalWidth === width){
      event.currentTarget.cropper.setCanvasData({
        width: cropData.width,
        height: cropData.height,
        top: cropData.top,
        left: cropData.left,
      });
    }
  }

  const onChangeSlider: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setZoom(parseFloat(e.target.value));
  };

  const onPostImageData = () => {
    if (!cropperRef) return;
    cropperRef.getCroppedCanvas({ width: 2048, fillColor: "#EEEEEE" }).toBlob((blob) => {
      if (!blob) return;
      if (postImage) postImage(new File([blob], 'unTitle.jpg', { type: blob.type }));
    }, 'image/jpeg');
  };

  // iconなどbase64データで送る場合はこっちで戻す
  const onCreateImageData: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    if (!cropperRef) return;
    cropperRef.getCroppedCanvas({ width: 2048, fillColor: "#EEEEEE" }).toBlob((blob) => {
      if (!blob) return;
      const fileReader = new FileReader();

      fileReader.onload = function () {
        if (onSave) onSave(this.result as string);
      };

      fileReader.readAsDataURL(blob);
    });
    if (postImage) onPostImageData();
  };

  const align = (direction: 'left' | 'center' | 'right' | 'top' | 'middle' | 'bottom') => {
    if (!cropperRef) return;
    const cropBoxData = cropperRef.getCropBoxData();
    const canvasData = cropperRef.getCanvasData();
    const imageData = cropperRef.getImageData();
    switch (direction) {
      case 'left':
        cropperRef.moveTo(cropBoxData.left, canvasData.top);
        break;
      case 'center':
        cropperRef.moveTo(
          cropBoxData.left + cropBoxData.width / 2 - imageData.width / 2,
          canvasData.top
        );
        break;
      case 'right':
        cropperRef.moveTo(cropBoxData.left + cropBoxData.width - imageData.width, canvasData.top);
        break;
      case 'top':
        cropperRef.moveTo(canvasData.left, cropBoxData.top);
        break;
      case 'middle':
        cropperRef.moveTo(canvasData.left, cropBoxData.top + cropBoxData.height / 2 - imageData.height / 2);
        break;
      case 'bottom':
        cropperRef.moveTo(canvasData.left, cropBoxData.top + cropBoxData.height - imageData.height);
        break;
    }
  };

  return (
    <div className="mbx-cropper">
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'upload-images',
      }}
    >
      <Cropper
        src={src as string}
        style={{ height: maxHeight, width: '100%' }}
        className="mkt_mask_items"
        // Cropper.js options
        guides={true}
        center
        ready={(event) => {
          preferredSizeCheck(event);
        }}
        dragMode="move"
        aspectRatio={width / height}
        minCropBoxWidth={width/2}
        minCropBoxHeight={height/2}
        cropBoxMovable={false}
        cropBoxResizable={false}
        zoomTo={zoom}
        onInitialized={(cropper) => {
          setCropperRef(cropper);
        }}
        zoom={(e) => setZoom(e.detail.ratio)}
      />
      <div className="mbx-cropper_control">
        <div className="mbx-cropper_img-align">
          <button onClick={() => align('left')}>
            <img src="/images/icon-align-left.svg" alt="" />
          </button>
          <button onClick={() => align('center')}>
            <img src="/images/icon-align-center.svg" alt="" />
          </button>
          <button onClick={() => align('right')}>
            <img src="/images/icon-align-right.svg" alt="" />
          </button>
          <button onClick={() => align('top')}>
            <img src="/images/icon-align-top.svg" alt="" />
          </button>
          <button onClick={() => align('middle')}>
            <img src="/images/icon-align-middle.svg" alt="" />
          </button>
          <button onClick={() => align('bottom')}>
            <img src="/images/icon-align-bottom.svg" alt="" />
          </button>
        </div>

        <input type="range" step="0.0001" min={0} value={zoom} max={1} onChange={onChangeSlider} />

        <div className="mbx-cropper_button-wrapper">
          <BaseButton theme="secondary" size="m" width="large" onClick={onCancel}>
            キャンセル
          </BaseButton>

          <BaseButton
            theme="primary"
            size="m"
            width="large"
            onClick={onSave ? onCreateImageData : onPostImageData}
          >
            保存する
          </BaseButton>
        </div>
      </div>
    </DataLayerPushContainer>
    </div>
  );
};
