import cn from 'classnames';
import React, { useEffect, useRef, useState, VFC } from 'react';

import { CropperModal } from '@/components/common/Modal/Cropper';
import { useFileLimit } from '@/hooks/useFIleLimit';
import ImgIcon from '@/assets/images/OtherComponents/Square-thumbnail.svg';
import { FileResponse } from '@/utils/api-client';
import { useSelector } from 'react-redux';
import { State } from '@/redux/state';

type TProps = {
  src: string | undefined;
  className?: string;
  type?: 'preview';
  onIconSave?: (res: any) => void;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=275%3A901
 *
 * クロップコンポーネントでクロップした画像を共有のfile APIでアップロード 値の保存は onIconSave を親から受け取ってその中で処理する
 */
export const ProfileImageSquare: VFC<TProps> = ({ src, className = 'w-104', type, onIconSave }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const inputRef = useRef<HTMLInputElement>(null);
  const [showModal, setShowModal] = useState(false);
  const [cropperSrc, setCropperSrc] = useState('');
  const [imgSrc, setImgSrc] = useState(src);
  const { checkFileSize } = useFileLimit();
  const onClick = () => {
    inputRef.current?.click();
  };

  /**
   * 画像が選択されたらData URIに変換して画像クロップモーダルを表示
   */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    const imageFile = e.target.files[0];

    if (!checkFileSize(imageFile.size, 5)) return;
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.addEventListener(
      'load',
      function () {
        setCropperSrc(reader.result as string);
        setShowModal(true);
      },
      false
    );
  };

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const onClose = (): void => {
    setShowModal(false);
  };

  const onSave = (dataUri: string): void => {
    setShowModal(false);
    setImgSrc(dataUri);
    console.log(dataUri);
  };

  const postImage = (dataUri: any): void => {
    if (onIconSave) onIconSave(dataUri);
  };

  return (
    <div className={className}>
      <CropperModal
        isOpen={showModal}
        onClose={onClose}
        width={208}
        height={288}
        src={cropperSrc}
        onSave={onSave}
        postImage={postImage}
      />
      <div
        className={cn('mbx-profileImageSquare', {
          'mbx-profileImageSquare--preview': type === 'preview',
        })}
        onClick={onClick}
      >
        <img src={imgSrc ? imgSrc : ImgIcon} className="w-auto" style={{ height: '144px' }}></img>
        {type !== 'preview' && (
          <>
            <div className="icon">
              <i className="mbx-icon mbx-icon-Camera"></i>
            </div>
            <input className="hidden" type="file" accept=".jpg, .jpeg, .png, .gif" ref={inputRef} onChange={onChange} />
          </>
        )}
      </div>
    </div>
  );
};
