import React, { ReactNode, useEffect, useRef, useState, VFC, Dispatch, SetStateAction } from 'react';
import {
  EventType,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormProps,
  UseFormReturn,
  FieldValues,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

import { State } from '@/redux/state';
import { TPagesResumeFormValue } from '@/types/Pages/Resume';
import { MBResumeResponse, CareerHistory } from '@/utils/api-client/api';

export interface TCallback {
  (
    value: {
      [x: string]: unknown;
    },
    name?: string,
    type?: EventType
  ): void;
}

type TProps = {
  useFormProps: UseFormProps;
  onSubmit?: SubmitHandler<TPagesResumeFormValue>;
  fetchData?: unknown;
  children: ReactNode;
  watchInitData?: boolean;
  methods?: UseFormReturn<FieldValues, Record<string, unknown>>;
  id?: string;
  setUpdatedKey: Dispatch<SetStateAction<string[]>>;
  setBeforeUnload: (flag: boolean) => void;
};
/**
 * React-Hook-Formでフォーム作成する際のお決まりのセットをまとめたもの
 */
export const FormContainer: VFC<TProps> = ({
  useFormProps,
  children,
  onSubmit = (data) => {
    console.log(data);
  },
  fetchData,
  watchInitData = true,
  methods: propsMethods,
  id,
  setUpdatedKey,
  setBeforeUnload,
}) => {
  const user = useSelector((state: State) => state.user);
  const inputClearingInProgress = useSelector((state: State) => state.inputClearingInProgress);

  const formProps = {
    ...(id && { id: id }),
  };

  // refの定義
  const refInputClearingInProgress = useRef(inputClearingInProgress);
  // redux stateが更新されたらrefも更新するようにする
  useEffect(() => {
    refInputClearingInProgress.current = inputClearingInProgress;
  }, [inputClearingInProgress]);

  const methods = propsMethods ? propsMethods : useForm(useFormProps);
  const { handleSubmit, watch } = methods;

  // FetchDataの値設定時にはwatchを走らせないためのフラグ
  const [fetchDataLoading, setFetchDataLoading] = useState(true);

  const [FetchData, setFetchData] = useState<unknown>();

  useEffect(() => {
    setFetchData(cloneDeep(fetchData));
  }, [fetchData]);

  // filedがレンダリングされたか
  const isFiledRender = (value: { [x: string]: any }, name: string | undefined) => {
    if (!FetchData) return false;

    if (name === 'careers') {
      const resumeData = FetchData as MBResumeResponse;
      const data = (resumeData?.careers) ? Array.from(resumeData.careers.values()) : [];
      if (data.length !== value.careers.length) return true;
      // APIのデータとレンダリングデータの並び順が一緒ならレンダリング中
      return !data.every((career, index) => {
        return career.c_id === value.careers[index].c_id;
      });
    } else if (name === 'qualifications') {
      const resumeData = FetchData as MBResumeResponse;
      const data = (resumeData?.qualifications) ? Array.from(resumeData.qualifications.values()) : [];
      if (data.length !== value.qualifications.length) return true;
      // APIのデータとレンダリングデータの並び順が一緒ならレンダリング中
      return !data.every((qualification, index) => {
        return qualification.q_id === value.qualifications[index].q_id;
      });
    } else if (name === 'skills') {
      const careerData = FetchData as CareerHistory;
      const data = (careerData?.skills) ? Array.from(careerData.skills.values()) : [];
      if (data.length !== value.skills.length) return true;
      // APIのデータとレンダリングデータの並び順が一緒ならレンダリング中
      return !data.every((skill, index) => {
        return skill.id === value.skills[index].id;
      });
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (!FetchData && !watchInitData) return;

    // FetchDataの更新ではPatch処理を動かしたくないので処理中はフラグを立てる
    setFetchDataLoading(true);

    if (useFormProps.defaultValues) {
      Object.keys(useFormProps.defaultValues).forEach((v) => {
        if (useFormProps.defaultValues?.[v]) {
          methods.setValue(v, useFormProps.defaultValues?.[v]);
        }
      });
    }

    setUpdatedKey([]);
    setBeforeUnload(false);
    // FetchData更新終わり
    setFetchDataLoading(false);
  }, [FetchData]);

  //更新があったkeyを保存する
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (!isFiledRender(value, name)) return;
      setBeforeUnload(true);
      setUpdatedKey((prevState) => {
        if (prevState.indexOf(name as string) >= 0) return prevState;
        const newState: string[] = [...prevState, name as string];
        return newState;
      });
    });
    return () => subscription.unsubscribe();
  }, [watch, FetchData]);

  useEffect(() => {
    return () => {
      // 編集し保存せずにページ遷移した時用
      setBeforeUnload(false);
    };
  }, []);

  return (
    <FormProvider {...(methods as UseFormReturn<FieldValues, Record<string, unknown>>)}>
      <form {...formProps} onSubmit={handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};
