import { AxiosError, AxiosResponse } from 'axios';
import React, { useState, useEffect, useMemo, ComponentProps, useContext} from 'react';
import { useDispatch } from 'react-redux';
import { UseFormProps, SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import { NaviInpageMenu } from '@/components/common/Navigation/Inpage/Menu';
import { Column, Wrapper } from '@/components/common/Page';
import { FormContainer } from '@/components/common/Form/Container';
import { ResumeFormSectionAddress } from '@/components/Resume/Form/SectionAddress';
import { ResumeFormSectionCareer } from '@/components/Resume/Form/SectionCareer';
import { ResumeFormSectionEducations, TFormValues } from '@/components/Resume/Form/SectionEducations';
import { ResumeFormSectionFamily } from '@/components/Resume/Form/SectionFamily';
import { ResumeFormSectionHopeJob } from '@/components/Resume/Form/SectionHopeJob';
import { ResumeFormSectionInterestThings } from '@/components/Resume/Form/SectionInterestThings';
import { ResumeFormSectionLastUpdate } from '@/components/Resume/Form/SectionLastUpdate';
import { ResumeFormSectionProfile } from '@/components/Resume/Form/SectionProfile';
import { ResumeFormSectionQualification } from '@/components/Resume/Form/SectionQualification';
import { ResumeFormSectionStatement } from '@/components/Resume/Form/SectionStatement';
import { ResumeHeader } from '@/components/Resume/Header';
import { toggleLoading, setUploadedImage, setSaved, notificationError, setPrepareFrag } from '@/redux/index';
import { usePageResumeFetch, usePageResumeProgress } from '@/hooks/usePageResume';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { TPagesResumeFormValue } from '@/types/Pages/Resume';
import { MBResumeResponse, ResumeApi, ImagePhoto, PortfolioApi, Resume, CareerSummaryModel, Qualification, QualificationQTypeEnum, ResponseError, FileResponse, EducationData, EducationPatchRequest } from '@/utils/api-client/api';
import { formatDate } from '@/utils/utils';

// Section用の型指定 共有なので読み込み側で指定してimportさせてる
export type TSectionProps = {
  id?: string;
  fetchData?: Resume | undefined;
};

/**
 * # 7-1-1 履歴書 編集 /mypage/resume/edit
 * UI PC https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=426%3A42863
 * UI SP https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=685%3A35345
 * API https://matchbox-2021-doc-api-git-develop-prty.vercel.app/#operation/get-resume
 */
export const PagesResumeEdit = (): React.ReactElement => {
  return (
    <Wrapper
      header={
        <div className="sticky top-0 z-10">
          <ResumeHeader>
            <PageMenuContent></PageMenuContent>
          </ResumeHeader>
        </div>
      }
    >
      <Column
        main={<PageMainContent></PageMainContent>}
        menu={
          <div className="mbx-naviInpage flex justify-end py-48 sm:hidden">
            <PageMenuContent></PageMenuContent>
          </div>
        }
      />
    </Wrapper>
  );
};

const PageMainContent = (): React.ReactElement => {
  const dispatch = useDispatch();
  const { push } = useContext(DataLayerContext);
  const getResumeData = usePageResumeFetch();
  const history = useHistory();
  const [resumeData, setResumeData] = useState<Resume | undefined>();
  const [iconSrc, setIconSrc] = useState<any | undefined>(resumeData?.user?.user_info?.photo?.f_url);
  const [iconState, setIconState] = useState<'stay'| 'change' | 'delete'>('stay');
  const [newEducation, setNewEducation] = useState<TFormValues | undefined>();
  const [updateEducations, setUpdateEducations] = useState<Array<number>>([]);
  const [delteEducations, setDelteEducations] = useState<{e_id: string, index: number}[]>([]);
  const [updatedKey, setUpdatedKey] = useState<string[]>([]);
  const resumeKey = {
    user: ['name', 'furigana', 'gender', 'birth', 'postal_code', 'address2', 'address1', 'email', 'tel'],
    careers: ['careers'],
    qualifications: ['qualifications'],
    resume: ['statement', 'interest_things', 'hope_job', 'dependent_family', 'partner', 'duty_of_support'],
  };

  const setBeforeUnload = (flag: boolean) => {
    dispatch(setPrepareFrag(flag));
  };

  useEffect(() => {
    if (getResumeData) {
      const {result, ...resumeData} = getResumeData;
      setResumeData(resumeData);
    }
  }, [getResumeData]);

  useEffect(() => {
    if (resumeData?.user?.user_info?.photo) setIconSrc(resumeData?.user?.user_info?.photo.f_url);
  }, [resumeData]);

  const birth = resumeData
    ? new Date(
        `${resumeData.user?.user_info?.birth_year}/${resumeData.user?.user_info?.birth_month}/${resumeData.user?.user_info?.birth_day}`
      )
    : new Date();

  const careers: CareerSummaryModel[] = [];
  if (resumeData?.careers) {
    Array.from(resumeData.careers.values()).forEach((career) => {
      const careersData: CareerSummaryModel = {
        c_id: '',
        c_company_name: '',
        c_join_date: '',
        c_leave_date: '',
        c_visible: true,
      };
      careersData.c_id = career.c_id;
      careersData.c_company_name = career.c_company_name;
      careersData.c_join_date = career.c_join_date;
      careersData.c_leave_date = career.c_leave_date && career.c_leave_flag ? career.c_leave_date : '現在';
      careersData.c_visible = career.c_visible;

      careers.push(careersData);
    });
  }

  const qualifications: Qualification[] = [];
  if (resumeData?.qualifications) {
    Array.from(resumeData.qualifications.values()).forEach((qualification) => {
      const qualificationsData: Qualification = {
        q_id: '',
        q_type: QualificationQTypeEnum.Text,
        q_title: '',
        q_date: '',
        q_score: '',
        q_visible: true,
      };
      qualificationsData.q_id = qualification.q_id;
      qualificationsData.q_type = qualification.q_type;
      qualificationsData.q_title = qualification.q_title;
      qualificationsData.q_date = qualification.q_date;
      qualificationsData.q_score = qualification.q_score;
      qualificationsData.q_visible = qualification.q_visible;

      qualifications.push(qualificationsData);
    });
  }

  const useFormProps: UseFormProps = {
    mode: 'onBlur',
    defaultValues: {
      name: resumeData ? resumeData.user?.user_info?.name : '',
      furigana: resumeData ? resumeData.user?.user_info?.furigana : '',
      gender: resumeData ? resumeData.user?.user_info?.gender?.toString() : '',
      birth: formatDate(birth, 'YYYY年MM月DD日'),
      tel: resumeData ? resumeData.user?.user_info?.tel : '',
      postal_code: resumeData ? resumeData.user?.user_info?.postal_code : '',
      email: resumeData ? resumeData.user?.user_info?.email : '',
      address1: resumeData ? resumeData.user?.user_info?.address1 : '',
      address2: resumeData ? resumeData.user?.user_info?.address2 : '',
      careers: careers,
      qualifications: qualifications,
      statement: resumeData ? resumeData.resume?.statement : '',
      interest_things: resumeData ? resumeData.resume?.interest_things : '',
      hope_job: resumeData ? resumeData.resume?.hope_job : '',
      dependent_family: resumeData ? resumeData.resume?.dependent_family : '',
      partner: resumeData ? resumeData.resume?.partner?.toString() : '',
      duty_of_support: resumeData ? resumeData.resume?.duty_of_support?.toString() : '',
      yyyy: resumeData && resumeData.user?.last_update_portfolio ? formatDate(new Date(resumeData.user?.last_update_portfolio), 'YYYY') : formatDate(new Date(), 'YYYY'),
      mm: resumeData && resumeData.user?.last_update_portfolio ? formatDate(new Date(resumeData.user?.last_update_portfolio), 'MM') : formatDate(new Date(), 'MM'),
      dd: resumeData && resumeData.user?.last_update_portfolio ? formatDate(new Date(resumeData.user?.last_update_portfolio), 'DD') : formatDate(new Date(), 'DD'),
    },
  };

  const onSubmit: SubmitHandler<TPagesResumeFormValue> = async (formData, e) => {
    const data: any = {
      user: {user_info: {}},
      careers: [],
      qualifications: [],
      resume: {},
      educations: [],
    };
    dispatch(toggleLoading(true));
    try {
      // 更新があったkeyのみを変換して利用する
      Object.keys(formData).forEach(function (key) {
        if(!updatedKey.includes(key)) return;

        if (resumeKey.user.includes(key)) {
          data.user.user_info[key] = (formData[key] !== "") ? formData[key] : null;
        } else if (resumeKey.careers.includes(key)) {
          data.careers = formData[key];
        } else if (resumeKey.qualifications.includes(key)) {
          data.qualifications = formData[key];
        } else if (resumeKey.resume.includes(key)) {
          if (formData[key] === 'true') {
            formData[key] = true;
          } else if (formData[key] === 'false') {
            formData[key] = false;
          }
          data.resume[key] = formData[key];
        }
      });

      // 学歴
      if (newEducation) {
        data.educations = await educationUpdate(newEducation, 'advance');
      }

      // 証明写真
      if (iconState === "change" && iconSrc) {
        const result = await new PortfolioApi().postFiles(iconSrc);
        push({
          event: 'fileUpload',
          actionType: 'file_upload',
          actionName: '画像',
        });
        const fileData: FileResponse = result.data;
        data.user.user_info.photo = {
          f_id: fileData.f_id,
        };
      } else if (iconState === "delete") {
        data.user.user_info.photo = {};
      }

      // 履歴書
      const res = await new ResumeApi().patchResume(data as Resume);

      // 証明写真削除
      if (iconState !== "stay" && resumeData?.user?.user_info?.photo?.f_id) {
        await new PortfolioApi().deleteFiles(resumeData?.user?.user_info?.photo.f_id);
        dispatch(setUploadedImage(null));
      };

      const {result, ...resume} = res.data;
      setResumeData(resume);
      setIconState('stay');
      setUpdatedKey([]);
      setUpdateEducations([]);
      setDelteEducations([]);
      setNewEducation(undefined);
      setBeforeUnload(false);
      dispatch(setSaved(true));
      // 確認ボタンの場合はプレビューに進む
      const event = e as React.BaseSyntheticEvent<Record<string, unknown>, any, any> | undefined;
      const button = event?.nativeEvent?.submitter as HTMLButtonElement;
      if (button.textContent === "反映を確認する") history.push("/mypage/resume");
    } catch (e) {
      // ロールバック処理
      if (newEducation && resumeData?.educations) {
        await educationUpdate({educations: Array.from(resumeData?.educations)} as TFormValues, 'revert');
      }
      if (data.user.user_info.photo && data.user.user_info.photo.f_id) {
        await new PortfolioApi().deleteFiles(data.user.user_info.photo.f_id);
      }
      await new ResumeApi().patchResume(resumeData);

      const error = e as AxiosError<ResponseError>;
      if (error.response) dispatch(notificationError(error.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const educationUpdate = async (data: TFormValues, state: 'advance' | 'revert'): Promise<EducationData[]> => {
    const postEducations: (EducationPatchRequest & {index: number})[] = [];
    const patchEducations: EducationPatchRequest[] = [];
    const delEducations: { e_id: string, index: number}[] = (state === 'advance') ? delteEducations : [];
    const newEducations: EducationData[] = cloneDeep(data.educations);

    data.educations.forEach((value, index) => {
      const data: EducationPatchRequest = {
        e_id: value.e_id,
        e_school_name: value.e_school_name,
        e_graduate_date: value.e_graduate_date_yyyy + '-' + value.e_graduate_date_mm,
      };
      if (!value.e_graduate_date_yyyy && !value.e_graduate_date_mm) data.e_graduate_date = null;
      if (!data.e_id) postEducations.push({...data, index: index});
      if (state === 'advance' && updateEducations.includes(Number(data.e_id))) {
        patchEducations.push(data);
      } else if (state === 'revert') {
        const revertValue = value as EducationData;
        data.e_graduate_date = revertValue.e_graduate_date;
        patchEducations.push(data);
      }
    });

    if (state === 'revert') {
      // 既に登録された学歴を取得する
      new ResumeApi().getResume().then((res) => {
        const response = res.data;
        if (response.educations) {
          Array.from(response.educations).forEach((reRducation, index) => {
            if (data.educations.filter((education) => education.e_id === reRducation.e_id).length === 0) {
              delEducations.push({e_id: reRducation.e_id, index: index});
            }
          });
        }
      });
    }
    // 登録処理
    if (postEducations.length !== 0) {
      for (let i = 0; i < postEducations.length; i++) {
        const {index, ...postEducation} = postEducations[i]
        const res = await new ResumeApi().postResumeUserIdEducations(postEducation);
        const educations = res.data.educations;
        newEducations[index] = educations[educations.length - 1];
      };
    }
    // 更新処理
    if (patchEducations.length !== 0) {
      for (let i = 0; i < patchEducations.length; i++) {
        await new ResumeApi().patchResumeUserIdEducations(patchEducations[i]);
      }
    }
    // 削除処理
    if (delEducations.length !== 0) {
      for (let i = 0; i < delEducations.length; i++) {
        await new ResumeApi().deleteResumeEducations(delEducations[i].e_id);
      }
    }

    return newEducations;
  }

  return (
    <div className="pt-48 pb-100 sm:pt-24 sm:mb-144">
      <FormContainer
        useFormProps={useFormProps}
        onSubmit={onSubmit}
        fetchData={resumeData}
        id="pagesResumeEdit"
        setUpdatedKey={setUpdatedKey}
        setBeforeUnload={setBeforeUnload}
      >
        <div className="mbx-utils-stack-v--48">
          <ResumeFormSectionProfile
            id="Profile"
            iconSrc={iconSrc}
            setIconSrc={setIconSrc}
            setIconState={setIconState}
          />
          <ResumeFormSectionAddress id="Address" />
          <ResumeFormSectionEducations
            id="Education"
            fetchData={resumeData}
            newEducation={newEducation}
            setNewEducation={setNewEducation}
            setUpdateEducations={setUpdateEducations}
            setDelteEducations={setDelteEducations}
          />
          <ResumeFormSectionCareer id="Career" />
          <ResumeFormSectionQualification id="Qualification" />
          <ResumeFormSectionStatement id="Statement" />
          <ResumeFormSectionInterestThings id="InterestThings" />
          <ResumeFormSectionHopeJob id="HopeJob" />
          <ResumeFormSectionFamily id="Family" />
          <ResumeFormSectionLastUpdate />
        </div>
      </FormContainer>
    </div>
  );
};

type TMenuProps = ComponentProps<typeof NaviInpageMenu>;

const PageMenuContent = (): React.ReactElement => {
  const progressData = usePageResumeProgress();
  if (!progressData) return <></>;
  const menu = [
    ['プロフィール', 'Profile'],
    ['現住所', 'Address'],
    ['学歴', 'Education'],
    ['職歴', 'Career'],
    ['資格・免許', 'Qualification'],
    ['志望動機', 'Statement'],
    ['趣味・特技', 'InterestThings'],
    ['本人希望', 'HopeJob'],
    ['家族について', 'Family'],
  ].map((value, index) => {
    return {
      children: value[0],
      disabled: false,
      done: progressData.progress[index],
      href: value[1],
    };
  });

  const props: TMenuProps = {
    menu,
    progress: progressData.progress,
    type: 'large',
  };
  return <NaviInpageMenu {...props} />;
};
