import React, { useEffect, useMemo, useState, VFC } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Container, Draggable, OnDropCallback } from 'react-smooth-dnd';

import BaseButton from '@/components/common/Button/BaseButton';
import { FormLabel } from '@/components/common/Form/';
import { Sorting } from '@/components/common/Form/Unit/Sorting';
import { ModalEditable, ModalEditableBody } from '@/components/common/Modal/Editable';
import {
    Portfolio, PortfolioItem, PortfolioPagePPageLayoutEnum, Question
} from '@/utils/api-client/index';
import { delay } from '@/utils/delay';

import { QuestionField } from './QuestionField';

const questionFields: Question[] = [
  { qa_id: 'b_v2_1', qa_category: '関わり方', qa_question: '職種', qa_answer: [] },
  {
    qa_id: 'b_v2_2',
    qa_category: '関わり方',
    qa_question: '担当内容',
    qa_answer: [],
  },
  {
    qa_id: 'b_v2_7',
    qa_category: '関わり方',
    qa_question: '使用ツール',
    qa_answer: [],
  },
  { qa_id: 'b_v2_8', qa_category: '関わり方', qa_question: '経験', qa_answer: [] },
];

const PortforioSectionComponent: VFC<{
  title: string;
  placeholder: string;
  qaId: string;
  portfolio?: Portfolio;
  onUpdateSections(sections: string[]): void;
  setBeforeUnload?(flag: boolean): void;
  itemId: number;
}> = ({ title, placeholder, qaId, portfolio, itemId, onUpdateSections, setBeforeUnload }) => {
  const [init, setInit] = useState<boolean>(true);
  const [showEditor, setShowEditor] = useState<boolean>(false);

  const item = useMemo<PortfolioItem | undefined>(() => {
    if (!portfolio?.items) return undefined;
    return Array.from(portfolio?.items).find((item) => item.i_id === itemId);
  }, [portfolio]);

  const answers = useMemo<string[] | undefined>(() => {
    if (!item?.i_pages) return undefined;
    const question = Array.from(item?.i_pages).find(
      (page) => page.p_page_layout === PortfolioPagePPageLayoutEnum.Questions
    );

    if (!question?.p_contents) return undefined;
    let contents = Array.from(question?.p_contents);

    // v2で追加された質問がデータに含まれない場合は空のデータを用意する
    const contentsIncludeV2Question1: boolean =
      Array.from(question?.p_contents).filter((content) => (content as Question).qa_id === 'b_v2_1')
        .length > 0;
    const contentsIncludeV2Question2: boolean =
      Array.from(question?.p_contents).filter((content) => (content as Question).qa_id === 'b_v2_2')
        .length > 0;
    const contentsIncludeV2Question7: boolean =
      Array.from(question?.p_contents).filter((content) => (content as Question).qa_id === 'b_v2_7')
        .length > 0;
    const contentsIncludeV2Question8: boolean =
      Array.from(question?.p_contents).filter((content) => (content as Question).qa_id === 'b_v2_8')
        .length > 0;

    if (!contentsIncludeV2Question1) {
      contents = [...contents, questionFields[0]];
    }
    if (!contentsIncludeV2Question2) {
      contents = [...contents, questionFields[1]];
    }
    if (!contentsIncludeV2Question7) {
      contents = [...contents, questionFields[2]];
    }
    if (!contentsIncludeV2Question8) {
      contents = [...contents, questionFields[3]];
    }

    const content = contents.find((content) => (content as Question).qa_id === qaId);
    return (content as Question)?.qa_answer;
  }, [item]);

  useEffect(() => {
    if (!answers) return;
    if (!init) return;

    (async () => {
      if (answers.length === 0) {
        methods.setValue('qa_answer', [{ answer: '' }]);
      } else {
        methods.setValue(
          'qa_answer',
          answers.map((answer) => ({ answer }))
        );
        // setValueだとfieldsが更新されない場合がある
        replace(answers.map((answer) => ({ answer })));
      }

      //初期のsetValueを待ってからsetInitする
      await delay(100);
      setInit(false);
    })();
  }, [answers]);

  const methods = useForm({
    defaultValues: {
      qa_answer: [{ answer: '' }],
    },
  });

  const onBlur = () => {
    if (setBeforeUnload) setBeforeUnload(true);
    const value = methods.getValues();
    onUpdateSections(value.qa_answer.map((q: any) => q.answer));
  };

  const { fields, remove, append, move, replace } = useFieldArray({
    control: methods.control,
    name: `qa_answer`,
  });

  const onDrop: OnDropCallback = ({ removedIndex, addedIndex }) => {
    if (removedIndex !== null && addedIndex !== null) {
      move(removedIndex, addedIndex);
      onBlur();
    }
  };

  const onAppend: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    append({ answer: '' });
    onBlur();
  };

  const onDelete = (index: number) => {
    remove(index)
    onBlur();
  };

  const onSort: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    if (fields.length < 2) return;
    setShowEditor(true);
  };

  const onHideSortModal = () => {
    setShowEditor(false);
  };

  return (
    <div className="space-y-8 my-22">
      <FormProvider {...methods}>
        <form>
          <FormLabel label={title} type="small" />
          <Container
            onDrop={onDrop}
            dragHandleSelector=".dragHandle"
            lockAxis="y"
            render={(ref) => (
              <div ref={ref} className="mb-8 sm:mb-18 mbx-formUnit-education">
                {fields.map((field: any, answerIndex: number) => (
                  <Draggable key={field.id} className="mbx-formUnit-education-box">
                    <QuestionField
                      key={field.id}
                      name={`qa_answer.${answerIndex}.answer`}
                      defaultValue={''}
                      placeholder={placeholder}
                      className="mkt_mask_items"
                      onRemove={() => {
                        if (fields.length > 1) onDelete(answerIndex);
                      }}
                      onBlur={onBlur}
                    />
                  </Draggable>
                ))}
              </div>
            )}
          />

          <div className="flex sm:space-x-8">
            <BaseButton iconName="Add" size="s" theme="tertiary" onClick={onAppend}>
              {`${title}を追加`}
            </BaseButton>
            <div className="lg:hidden md:hidden">
              <BaseButton
                iconName="Sortable"
                size="s"
                theme="quaternary"
                disabled={fields.length < 2}
                onClick={onSort}
              >
                並べ替え
              </BaseButton>
            </div>
          </div>
        </form>
      </FormProvider>

      {showEditor && (
        <ModalEditable>
          <ModalEditableBody onClose={onHideSortModal}>
            <Container
              onDrop={onDrop}
              dragHandleSelector=".dragHandle"
              render={(ref) => {
                return (
                  <div ref={ref}>
                    {fields.map((field, index) => (
                      <Draggable key={field.id}>
                        <Sorting
                          handle="dragHandle"
                          onDelete={() => {
                            if (fields.length > 1) onDelete(index);
                          }}
                          className="mkt_mask_items"
                        >
                          <>
                            {field.answer}
                            {/* {dateString(item.q_date)}
                          <br />
                          {item.q_title} */}
                          </>
                        </Sorting>
                      </Draggable>
                    ))}
                  </div>
                );
              }}
            />
          </ModalEditableBody>
        </ModalEditable>
      )}
    </div>
  );
};

export const PortforioSection = React.memo(PortforioSectionComponent);
