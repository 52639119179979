import React, { useEffect, useMemo, useState, VFC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { cloneDeep } from 'lodash';

import { FormContainerTextArea, FormContainerTextfield } from '@/components/common/Form/';
import {
    Portfolio, PortfolioItem, PortfolioPage, PortfolioPagePPageLayoutEnum, Question
} from '@/utils/api-client/index';
import { delay } from '@/utils/delay';

export const PortfolioEditPoints: VFC<{
  portfolio?: Portfolio;
  itemId: number;
  onUpdatePoints(qaId: string, question: string, answer: string[]): void;
  setBeforeUnload(flag: boolean): void;
}> = ({ portfolio, itemId, onUpdatePoints, setBeforeUnload }) => {
  const methods = useForm();
  const [init, setInit] = useState<boolean>(true);

  const pointFields = [
    { qa_id: 'c_1', qa_category: 'ポイント', qa_question: '', qa_answer: [{ answer: '' }] }, //意図・ポイント
    { qa_id: 'c_2', qa_category: 'ポイント', qa_question: '', qa_answer: [{ answer: '' }] }, //問題と解決策
    { qa_id: 'c_3', qa_category: 'ポイント', qa_question: '', qa_answer: [{ answer: '' }] }, //結果
  ];

  const pointQuestionPlaceholder = ['例）意図・ポイント', '例）問題と解決策', '例）結果'];

  const pointAnswerPlaceholder = [
    '例）膨大な商品情報をユーザーの目的ごとにアクセスできるように再整理し、各商品群を色で識別できるようにしました。（最大300文字）',
    '例）スケジュールに対し、制作ボリュームが多く社内リソースだけでは納期が間に合わない状態でした。そのため、外部のフリーランスのデザイナーを集め彼らをディレクションし、乗り越えました。（最大300文字）',
    '例）サイトリニューアル後、トップページでの離脱率が半分に減り、商品の問い合わせが2割増加しました。（最大300文字）',
  ];

  const item = useMemo<PortfolioItem | undefined>(() => {
    if (!portfolio?.items) return undefined;
    return Array.from(portfolio?.items).find((item) => item.i_id === itemId);
  }, [portfolio]);

  const questions = useMemo<Question[]>(() => {
    if (!item) return [];
    const page: PortfolioPage | undefined = Array.from(item.i_pages).find(
      (page) => page.p_page_layout === PortfolioPagePPageLayoutEnum.Questions
    );
    const questions = Array.from(page?.p_contents || []);
    return questions as Question[];
  }, [item]);

  const onBlur = (name: string) => {
    //初期のsetValueが終わってからじゃないと更新は受け付けない
    if (init) {
      return;
    }

    const value = methods.getValues();
    const copy = cloneDeep(value);
    if (name.includes('c_1')) {
      onUpdatePoints('c_1', copy.c_1.qa_question, copy.c_1.qa_answer);
    }
    if (name.includes('c_2')) {
      onUpdatePoints('c_2', copy.c_2.qa_question, copy.c_2.qa_answer);
    }
    if (name.includes('c_3')) {
      onUpdatePoints('c_3', copy.c_3.qa_question, copy.c_3.qa_answer);
    }
    setBeforeUnload(true);
  };

  useEffect(() => {
    if (!item) return;
    if (!init) return;
    if (questions.length === 0) return;
    (async () => {
      questions.forEach((question) => {
        switch (question.qa_id) {
          case 'c_1':
            methods.setValue('c_1.qa_question', question.qa_question);
            methods.setValue('c_1.qa_answer', question.qa_answer);
            break;
          case 'c_2':
            methods.setValue('c_2.qa_question', question.qa_question);
            methods.setValue('c_2.qa_answer', question.qa_answer);
            break;
          case 'c_3':
            methods.setValue('c_3.qa_question', question.qa_question);
            methods.setValue('c_3.qa_answer', question.qa_answer);
            break;
        }
      });
      //初期のsetValueを待ってからsetInitする
      await delay(10);
      setInit(false);
    })();
  }, [item, init, questions]);

  return (
    <FormProvider {...methods}>
      <form>
        {pointFields.map((point, index) => (
          <div key={index} className="space-y-8 my-22">
            <FormContainerTextfield
              className="mkt_mask_items"
              name={`${point.qa_id}.qa_question`}
              defaultValue={point.qa_question}
              placeholder={pointQuestionPlaceholder[index]}
              onBlur={() => onBlur(`${point.qa_id}`)}
            />
            <FormContainerTextArea
              className="mkt_mask_items"
              rows={6}
              maxLength={300}
              name={`${point.qa_id}.qa_answer.0`}
              placeholder={pointAnswerPlaceholder[index]}
              onBlur={() => onBlur(`${point.qa_id}`)}
            />
          </div>
        ))}
      </form>
    </FormProvider>
  );
};
