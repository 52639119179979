import React from 'react';
import { UseFormProps } from 'react-hook-form';

import { FormContainer } from '@/components/common/Form/Container';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLayoutUnitDivider } from '@/components/common/Form/Layout/UnitDivider';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { TSectionProps } from '@/pages/Resume/Edit';
import { formatDate } from '@/utils/utils';

export const ResumeFormSectionLastUpdate = (): React.ReactElement => {

  return (
    <>
      <TitleSectionTitle>履歴書の更新日</TitleSectionTitle>
      <div className="flex mbx-utils-stack-h--8">
        <FormContainerTextfield
          name="yyyy"
          readOnly={true}
          className="w-auto"
        ></FormContainerTextfield>
        <FormLayoutUnitDivider>年</FormLayoutUnitDivider>
        <FormContainerTextfield
          name="mm"
          readOnly={true}
          className="w-auto"
        ></FormContainerTextfield>
        <FormLayoutUnitDivider>月</FormLayoutUnitDivider>
        <FormContainerTextfield
          name="dd"
          readOnly={true}
          className="w-auto"
        ></FormContainerTextfield>
        <FormLayoutUnitDivider>日</FormLayoutUnitDivider>
        <FormLayoutUnitDivider>現在</FormLayoutUnitDivider>
      </div>
    </>
  );
};
