import React from 'react';
import { FieldValues, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { FormContainer, TCallback } from '@/components/common/Form/Container';
import { FormContainerTextArea } from '@/components/common/Form/Container/TextArea';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { PLACEHOLDER } from '@/definition/PLACEHOLDER';
import { usePolling } from '@/hooks/usePolling';
import { TSectionProps } from '@/pages/Resume/Edit';
import { ResumeApi } from '@/utils/api-client';

export const ResumeFormSectionHopeJob = ({ id }: TSectionProps): React.ReactElement => {

  return (
    <div id={id}>
      <TitleSectionTitle>
        <div className="flex items-center">
          <span className="mr-4">本人希望</span>
          <TooltipMessageMatchbou
            className=""
            message="オファー機能ご利用時、「本人希望」は企業に開示されません。"
            position={['0', '-94px']}
          >
            <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
          </TooltipMessageMatchbou>
        </div>
      </TitleSectionTitle>
      <div>
        <FormContainerTextArea
          name="hope_job"
          rows={5}
          maxLength={150}
          className="mkt_mask_items"
          placeholder={PLACEHOLDER.hope_job}
          noLineBreaks={true}
        />
      </div>
    </div>
  );
};
