import { DataLayerType } from '@/types/DataLayerType';
import {
    FileResponse, MatchboxUserResponse, MyNavi, Portfolio, Status, UserInfo
} from '@/utils/api-client/';

export type State = {
  loading: boolean;
  forceLoading: boolean; // 強制ローディング表示用フラグ
  isLoggedIn: boolean; //ログインアクションを実行したか。ログイン状態を示すのはisAuth
  isLoggedOut: boolean; //ログアウトアクションを実行したか。ログイン状態を示すのはisAuth
  isAuth?: boolean;
  user: UserInfo | null;
  userBasic: MatchboxUserResponse | null;
  portfolio: (Portfolio & Status) | undefined; //公開ポートフォリオのデータを保持する（ページ遷移時にパスワード認証状態を維持するため）
  oldPortfolio: (Portfolio & Status) | undefined; //Portfolio保存時のロールバック用のデータ
  mynavi: MyNavi | null;
  errorMessage: string | null;
  successMessage: string | null;
  portfolioStatusOfPDF: number | null; // PDFダウンロードのSnackBarの表示制御
  portfolioTicketID: number | undefined;
  portfolioPDFDownloadModal: boolean; // PDFダウンロードのSnackBarの表示制御
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  imageCropper: any;
  uploadedImage: FileResponse | null;
  saved: boolean; // 保存完了通知表示用
  prepareFrag: boolean; // ポーリングhookでデータ保存中
  pathAfterLogin: string | undefined; // ログイン成功後の遷移先
  pathAfterLogout: string; // ログアウト成功後の遷移先
  inputClearingInProgress: boolean; // インプット要素のクリア処理で自動パッチ処理が動かないのでフラグを指定
  dataLayer: DataLayerType; //解析用に送信するパラメータ
  dataLayerChangedBy: string | null;
};

export const initialState: State = {
  loading: false,
  forceLoading: false,
  isLoggedIn: false,
  isLoggedOut: false,
  user: null,
  userBasic: null,
  portfolio: undefined,
  oldPortfolio: undefined,
  mynavi: null,
  errorMessage: null,
  successMessage: null,
  portfolioStatusOfPDF:
    localStorage.getItem('portfolioStatusOfPDF') === '3'
      ? Number(localStorage.getItem('portfolioStatusOfPDF'))
      : null,
  portfolioTicketID: localStorage.getItem('portfolioTicketID')
    ? Number(localStorage.getItem('portfolioTicketID'))
    : undefined,
  portfolioPDFDownloadModal: false,
  imageCropper: null,
  uploadedImage: null,
  saved: false,
  prepareFrag: false,
  pathAfterLogin: undefined,
  pathAfterLogout: '/login',
  inputClearingInProgress: false,
  dataLayer: {},
  dataLayerChangedBy: null,
};
