import cn from 'classnames';
import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BaseButton from '@/components/common/Button/BaseButton';
import { MyNaviSelectForm } from '@/components/common/Direct/MyNaviSelectForm';
import { NotificationMatchbou } from '@/components/common/Notification/Matchbou';
import { Wrapper } from '@/components/common/Page';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { notificationSuccess } from '@/redux/index';
import { State } from '@/redux/state';
import { MyNavi } from '@/utils/api-client';
import { progressFromMyNavi } from '@/utils/utils';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
/**
 * # マイページ 希望条件編集 /mypage/condition
 * UI PC https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=487%3A7054
 *
 */
export const MypageCondition = (): React.ReactElement => {
  const dispatch = useDispatch();
  const loading = useSelector((state: State) => state.loading);
  const { push } = useContext(DataLayerContext);
  const [notificationMatchbouShow, setNotificationMatchbouShow] = useState(true);
  const [progressDigit, setProgressDigit] = useState(0);

  // フォームの値に変更があったら進行度は計算
  const onChangeForm = (formValue: MyNavi) => {
    setProgressDigit(progressFromMyNavi(formValue));
  };

  const onHideNotificationMatchbou = () => {
    setNotificationMatchbouShow(false);
  };

  // 送信完了後の処理
  const submitCallback = () => {
    push({
      event: 'conditionUpdate',
      actionType: 'condition_update',
      actionName: 'condition_update',
    });
    dispatch(notificationSuccess('経験・希望の変更が完了しました。'));
  };

  return (
    <Wrapper
      header={
        <div className="sticky top-0 z-20">
          <HeaderTitle title={'経験・希望の設定'}>
            <div className="flex items-center	justify-end">
              <div className="w-186 sm:w-140">
                <BaseButton
                  theme='secondary'
                  size="40"
                  className="w-full"
                  type="submit"
                  disabled={loading}
                  form="myNaviSelectForm"
                >
                  変更を保存
                </BaseButton>
              </div>
            </div>
          </HeaderTitle>
          <div className='bg-white h-16 sm:h-0'></div>
          <div className={cn('relative', { 'pointer-events-none': !notificationMatchbouShow })}>
            <NotificationMatchbou
              digit={progressDigit}
              show={notificationMatchbouShow}
              onClick={onHideNotificationMatchbou}
            >
              経験・希望を入力すると、企業の目に留まりやすくなります。情報を埋めて完成させよう！
            </NotificationMatchbou>
          </div>
        </div>
      }
    >
      <>
        <div className="mb-96 sm:mb-64">
          <p className="text-center text-body_1 mb-15 mt-120">
            職務経験や希望条件をご登録いただくことで、
            <br />
            マイナビワークスからあなたにぴったりな求人情報をご提供できます。
            <br />
            この情報はいつでも更新することができます。
          </p>
          <MyNaviSelectForm afterSending={submitCallback} onChangeForm={onChangeForm} />
        </div>
      </>
    </Wrapper>
  );
};
